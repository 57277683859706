/* stylelint-disable selector-no-qualifying-type -- not relevant for custom elements */

cog-credits {
    --cog-logo--size: 30px;

    all: unset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: var(--cog-logo--size, 30px);
    margin-top: var(--cog-logo--size, 30px);
    overflow: hidden;

    .cog-credits__inner {
        display: flex;
        flex-flow: row nowrap;
        width: fit-content;
        gap: 10px;
        transform: translateX(calc(100% - var(--cog-logo--size)));
    }

    &[open="true"] {
        .cog-credits__inner {
            transform: unset;
        }
    }

    a {
        display: flex;
        align-self: center;
        white-space: nowrap;

        svg {
            fill: currentColor;
            flex-shrink: 0;
            width: 125px;
            border-bottom: 1px solid transparent;
        }
    }

    .cog-credits__toggle {
        display: flex;

        svg {
            width: var(--cog-logo--size, 30px);
            height: var(--cog-logo--size, 30px);
        }
    }



    @media screen and (prefers-reduced-motion: no-preference) {
        .cog-credits__inner {
            transition: transform 0.8s ease;
        }

        .cog-credits__toggle {
            transition: rotate 0.8s ease;
        }

        &[open="true"] {
            .cog-credits__toggle {
                rotate: -360deg;
            }
        }
    }
}
