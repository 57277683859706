@use '../0-helpers/' as *;

// Remove the top margin from the first child element and bottom margin from
// the last child element.
%margin-trim,
.margin-trim {
    > *:first-child {
        margin-block-start: 0;
    }

    > *:last-child {
        margin-block-end: 0;
    }
}

// Remove the top margin applied to the first child of this element.
%margin-trim-start,
.margin-trim-start {
    > *:first-child {
        margin-block-start: 0;
    }
}

// Remove the bottom margin applied to the last child of this element.
%margin-trim-end,
.margin-trim-end {
    > *:last-child {
        margin-block-end: 0;
    }
}
