@use "../base" as *;

.page-header {
    position: relative;
    overflow: hidden;

    &.video-used {
        overflow: visible;
        
        .page-header__image {
            aspect-ratio: 9 / 16;

            @include mq(m) {
                aspect-ratio: 16 / 9;
            }
        }

        @include mq(m) {
            aspect-ratio: 16 / 9;
        }
    }

    &.page-header--overlay {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(45, 45, 45, 80) 12%, rgba(45, 45, 45, 0) 100%);
            z-index: 3;
            height: 126px;

            @include mq(m) {
                height: 480px;
                mix-blend-mode: multiply;
                background: linear-gradient(180deg, rgba(0, 0, 0, 1) 8%, rgba(45, 45, 45, 0) 34%);
            }
        }

        .page-header__content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 26px;
            padding-bottom: var(--space--xl);
            background: linear-gradient(
                360deg,
                #000 0%,
                rgba(0, 0, 0, 0.505) 72.5%,
                rgba(0, 0, 0, 0) 100%
            );
            z-index: 3;

            .container {
                position: relative;
            }
        }
    }

    &__image {
        position: relative;
        aspect-ratio: 3/ 4;
        max-height: 100dvh;
        width: 100%;
        container-type: size;

        @include mq(m) {
            aspect-ratio: 16 / 9;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &[data-focus-image] {
                --container-width: var(--image-init-width, 100cqw);
                --container-height: var(--image-init-height, 100cqh);
                --image-width: calc(var(--container-width) - 100%);
                --image-height: calc(var(--container-height) - 100%);

                object-position: clamp(
                        100%,
                        0.5 * var(--container-width) - var(--focus-x) *
                            var(--image-width),
                        0%
                    )
                    clamp(
                        100%,
                        0.5 * var(--container-height) - var(--focus-y) *
                            var(--image-height),
                        0%
                    );
            }
        }
    }

    &__content {
        @extend %margin-trim;

        padding-block: var(--space--xl);
        padding-top: 100px;
    }

    &__heading {
        @extend %type-heading-xl;

        margin-bottom: var(--space--base);
        position: relative;

        margin: 0;

        @include mq(m) {
            width: 85%;
            margin: 0 0 24px;
        }
    }

    &__subheading {
        @extend %type-h4;

        margin-block: var(--space--base);
    }

    .author {
        @include mq(m) {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 24px;
        }

        h4 {
            margin: 0 0 8px;
            font-weight: 700;

            @include mq(m) {
                margin: 0;
            }

            &.date {
                font-weight: 400;
            }
        }
    }
}
