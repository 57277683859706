@use "../base" as *;

.site-header {
    --site-header--logo--width: 60px;
    --site-header--logo--colour: var(--color--black);
    padding-block: 28px;
    background-color: var(--color--neutral-900);
    color: var(--color--white);

    @include mq(ml) {
        padding-block: 32px;
    }

    &.site-header--overlay {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 5;
        background: transparent;
    }

    &__logo {
        display: flex;
        align-items: center;
        // max-width: var(--site-header--logo--width);
        color: var(--site-header--logo--colour);
        gap: var(--space--20);

        .device {
            height: 64px;
            width: auto;
        }

        .logo {
            // height: 62px;
            width: 100%;
        }

        .desktop {
            display: none;

            @include mq(ml) {
                display: block;
            }
        }

        .mobile {
            display: block;

            @include mq(ml) {
                display: none;
            }
        }
    }

    &__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: var(--space--s);

        .mobile {
            margin-top: 0;
        }
    }

    &__menu-container {
        --gap: var(--space--m);
        --btn--icon-size: 24px;

        display: flex;
        gap: var(--gap);
        // padding-block: var(--space--base);

        @include mq($from: menu) {
            --gap: var(--space--m);
        }

        .btn-search,
        .btn-hamburger,
        .btn-cross,
        .btn-search-two,
        .btn-basket,
        .btn-account {
            display: block;
            text-align: center;
            border: 0;

            @extend %type-h5;
            font-weight: 600;
            color: var(--color--white);
            font-size: 11px;
            line-height: 24px;

            background: transparent;

            svg {
                margin: 0 auto 4px;
            }
        }

        .btn-search,
        .line {
            display: none;

            @include mq(ml) {
                display: block;
            }
        }
    }

    &__header-menu {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        gap: var(--space--xl);
        transition: all var(--transition--base);
        padding-right: 32px;

        @include mq($until: ml) {
            display: none;
        }

        a {
            @extend %type-h5;

            text-decoration: none;

            &:hover,
            &:focus-visible {
                text-decoration: underline;
            }
        }

        .menuDialog-dialog-is-open & {
            opacity: 0;
            transition: all var(--transition--base);
        }
    }
}
