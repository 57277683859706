@use "../base" as *;

.explore-block {
    padding-inline: var(--container--padding-inline);
    background-color: var(--color--neutral-950);
    padding-block: var(--space--xl);
    color: var(--color--white);

    @include mq(ml) {
        padding-inline: 0;
        background-color: var(--color--white);
        padding-block: 0;
        color: var(--color--black);
        max-width: 380px;
    }

    h4 {
        border-bottom: 1px solid;
        padding-bottom: var(--space--xs);
        margin-bottom: var(--space--s);
    }

    .card {
        &__content {
            background-color: transparent;
            padding: var(--space--s) var(--space--s) var(--space--m);

            @include mq(ml) {
                padding: var(--space--m);
            }
        }

        &__date {
            display: none;
            // color: var(--color--white);
            // font-weight: normal;

            // @include mq(m) {
            //     color: var(--color--black);
            // }
        }

        &__summary {
            display: none;

            // @include mq(m) {
            //     display: block;
            // }
        }

        &__tags-item {
            display: none;
            // background-color: var(--color--white);
            // color: var(--color--black);

            // @include mq(m) {
            //     background-color: var(--color--black);
            //     color: var(--color--white);
            // }
        }
    }
}
