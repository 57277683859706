@use "sass-mq" as *;
@use "../base" as *;

.tnew-body {
    // Button links - Text
    .tn-events-keyword-filter__btn-clear-all-keywords,
    .tn-events-list-view__btn-reset-dates,
    .tn-account-login-forgot-login-link,
    .tn-page-bottom a,
    .tn-edit-billing-link,
    .tn-edit-delivery-link,
    .tn-donation-ask-component a,
    .tn-contribution-type-form a,
    .tn-not-found-component a,
    .tn-syos-cart-seat__btn-remove {
        @extend %btn--link;
        opacity: 1 !important;
    }

    // Calendar Button
    .tn-btn-datepicker__btn {
        @extend %btn--mask-icon-after;
        align-items: center;

        .tn-btn-datepicker__icon-container {
            display: none;
        }

        &::after {
            background: url("https://oae-refresh-development.cogdesign.com/wp-content/themes/oae/public/images/calendar_today.svg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    // Main button
    .tn-cart-buttons__secondary-action,
    .btn-dismiss {
        @extend %btn;
    }

    .tn-cart-buttons__primary-action {
        padding: 8px 24px;
        margin-bottom: 0;
    }

    // Secondry btn - black
    #tn-login-button,
    #tn-account-register-button-register,
    .btn-save,
    #tn-payment-submit-button,
    #tn-update-button,
    .btn-primary,
    .tn-syos__btn-add-to-cart,
    .tn-syos-screen-button, 
    .tn-donation-ask-component__submit-button {
        @extend %btn;
        @extend %btn--secondary-large;
        width: 100%;
        height: auto;

        @include mq(m) {
            width: auto;
        }
    }

    // inline text link
    .tn-page-bottom {
        span {
            color: inherit !important;
        }
    }

    // White button on black
    .tn-register-button {
        @extend %btn;
        width: auto;
        background-color: var(--color--white);
        display: inline-flex;
        margin-bottom: var(--space--layout--xs);

        &:hover {
            border-color: var(--color--white);
        }
    }

    // White alt on black
    .tn-register-brief-button,
    .btn-info {
        @extend %btn;
        width: auto;
        border-color: var(--color--white);
        color: var(--color--white);
        display: inline-flex;
        background-color: transparent;
        transition: all 500ms ease;
        
        &:hover {
            border-color: var(--color--white);
            background-color: var(--color--white);
            color: var(--color--black);
        }
    }

    .btn.tn-btn-grouped~.btn.tn-btn-grouped {
        margin-top: 16px;
        flex-shrink: 0;
        @include mq(m) {
            margin-top: 0;
        }
    }

    .btn-default {
        background-color: transparent;
        color: var(--color--black);
        &:hover {
            background-color: var(--color--black);
            color: var(--color--white);
        }
    }

}
