@use "../base" as *;

.menuDialog-dialog-is-open,
.searchDialog-dialog-is-open {
    .site-header-outer {
        .logo-svg {
            @include mq($until: m) {
                opacity: 0;
            }
        }
    }

    // .page-wrap {
    //     filter: blur(5px);
    // }

    .site-header-menu {
        opacity: 0;
        transform: translateX(20px);
    }

    .site-header__menu-container {
        .btn-search,
        .btn-hamburger, 
        .line {
            opacity: 0;
            // transform: translateX(20px);
        }
    }
}

.menu-dialog-container {
    transition: all 600ms ease !important;
    overflow: hidden;

    &.dialog-container,
    .dialog-content,
    .dialog-overlay {
        background-color: transparent;
    }

    &.dialog-container {
        overflow: hidden;
        align-items: flex-start;
    }

    .dialog-content {
        width: 100%;
        margin-top: 0;
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        // background-color: var(--color--neutral-950);
        max-width: 100%;
        margin: 0;
    }

    .btn-donate {
        background: var(--color--green) !important;
        border: 0 !important;
        position: relative;
        display: flex !important;
        color: var(--color--black) !important;
        width: 100%;
        transition: all 600ms ease !important;
        margin-top: 64px;
        flex-direction: row;
        padding: var(--btn--padding-block) var(--btn--padding-inline) !important;

        @extend %type-h5;
        font-weight: 700;

        @include mq(m) {
            display: none !important;
        }

        svg {
            display: none;
            --btn--icon-size: 40px !important;
        }
    }
}

.background-wrap {
    position: relative;

    &::after {
        background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.9) -10%,
            rgba(0, 0, 0, 0.6) 100%
        );
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(0);
        transition: backdrop-filter 10s ease; 

        @include mq(m) {
            left: calc(100% - 497px);
        }

        @include mq(xxl) {
            left: 63%;
        }
    }

}

.menuDialog-dialog-is-open, .searchDialog-dialog-is-open {
    .background-wrap::after {
        backdrop-filter: blur(42px);  // Apply blur when the menu is open
    }
} 

.menu-dialog-content {
    height: 100vh;
    color: var(--color--black);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    // transition: clip-path var(--transition--duration) ease-out;
    overflow: scroll;
    position: relative;

    @include no-scrollbar;

    // > .container {
    //     @include mq($from: menu) {
    //         height: 100%;
    //     }
    // }

    .inner-content {
        height: auto;
        padding-bottom: 0;
    }

    .main-items {
        li {
            font-weight: var(--fw--semibold);
            color: var(--color--white);
            margin-bottom: var(--space--20);

            a {
                @extend %type-h1;
                text-decoration: none;

                &:hover {
                    color: var(--color--green);
                }
            }

            &:last-child {
                @include mq(m) {
                    margin-bottom: 32px;
                }
            }
        }
    }

    .toggle-menu {
        margin: 20px 0 48px;

        @include mq(m) {
            margin: 32px 0 150px;
        }

        .accordion-item {
            border: 0;
            // display: flex;
            // justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 0 0 var(--space--m);

            &::before {
                content: none;
            }

            &-toggle {
                flex-shrink: 0;
            }

            &__header {
                border: 0;
                padding: 0;
                width: auto;
                position: absolute;
                right: 0;
                top: 0;

                &[aria-expanded="true"] {
                    .accordion-item__icon {
                        &::before {
                            rotate: 0deg;
                            // background-color: var(--color--white);
                            // mask-image: url("../images/keyboard_arrow_down.svg");
                        }
                    }
                }
            }

            &__icon {
                &::before {
                    width: 23px;
                    rotate: 180deg;
                    background-color: var(--color--white);
                    mask-image: url("../images/keyboard_arrow_down.svg");
                }
            }

            &__heading {
                color: var(--color--white);

                @extend %type-h3;
                font-weight: var(--fw--semibold);

                a {
                    text-decoration: none;

                    &:hover {
                        color: var(--color--green);
                    }
                }
            }

            &__content {
                padding: 0;
                margin-block: 24px;

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        color: var(--color--white);

                        @extend %type-h5;

                        a {
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn--alt {
        justify-content: space-between;
        display: inline-flex;
        border: 1px solid var(--color--white);
        color: var(--color--white);
        width: 100%;
        height: 40px;
        font-size: 16px;
        padding: 0 16px;
        text-decoration: none;
        

        &:hover {
            cursor: pointer;
            border: 1px solid var(--color--black);
            background: var(--color--white);
            color: var(--color--black);
        }

        @include mq(m) {
            width: 260px;
            height: 53px;
            font-size: 20px;
            padding: 10px 34px;
        }
    }

    .socials {
        --btn--size: 44px;
        --icon-size: 28px;
        display: flex;
        gap: var(--grid--column-gap);
        margin-top: var(--space--l);
        align-items: center;

        @include mq(m) {
            margin-top: var(--space--xl);
        }

        a {
            padding: 0;
        }

        svg {
            fill: var(--color--white);
            width: var(--icon-size);
            height: auto;
        }
    }
}

.menu-dialog-header,
.inner-content {
    justify-content: space-between;
    padding: 28px 0 78px;

    .inner-wrapper {
        max-width: 600px;
    }

    @include mq(m) {
        width: 378px;
        margin: 0 -5px 0 auto;
        padding-top: 38px;
    }

    @include mq(l) {
        padding: 32px 0 80px;
        margin: 0 0 0 auto;
    }

    // @include mq(xl) {
    //     width: 460px;
    // }

    // @include mq(xxl) {
    //     width: 520px;
    // }

    .flex {
        display: flex;
        gap: 16px;
    }

    .btn-search,
    .btn-account,
    .btn-basket {
        opacity: 0;
        transition: all var(--transition--base);
    }

    .menuDialog-dialog-is-open & {
        .btn-search,
        .btn-account,
        .btn-basket {
            opacity: 1;
            transition: all var(--transition--base)
                calc(var(--transition--duration) / 2);
        }
    }
}

.site-header-main-menu {
    @extend %type-h5;

    a {
        text-decoration: none;

        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }
    }
}

.site-header-search {
    --height: 136px;

    position: static;
    opacity: 1;
    visibility: visible;
    height: var(--height);

    @include mq($from: menu) {
        --height: 68px;
    }

    &[aria-hidden="true"] {
        position: absolute;
        right: 200vw;
        opacity: 0;
        height: 0;
        visibility: hidden;
        overflow: hidden;
        transition: height var(--transition--base);
    }

    .search-form {
        @include mq($from: menu) {
            width: calc(50% - var(--space--s));
            margin-left: auto;
        }

        .search-form-wrapper {
            display: flex;
            flex-direction: column;
            gap: var(--space--xs);

            @include mq($from: menu) {
                flex-direction: row;
            }
        }
    }
}

.menuDialog-dialog-is-open,
.searchDialog-dialog-is-open {
    .page-wrap {
        filter: blur(5px);

        @include mq(m) {
            filter: blur(0px);
        }
    }

    .menu-dialog-content {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .menu-dialog-container {
        .cross-icon {
            path {
                animation: remove-transform var(--transition--duration) ease-out
                    forwards;
            }
        }

        .btn-book {
            animation: remove-transform var(--transition--duration) ease-out
                forwards;
        }
    }
}

@keyframes remove-transform {
    to {
        transform: none;
    }
}

#searchDialog {
    .inner-wrapper {
        margin: 0 auto;
        height: 300px;

        @include mq(m) {
            position: absolute;
            top: 50%;
            transform: translate(0px, -50%);
            padding-bottom: 40px;
            height: auto;
        }

        .search-form {
            label {
                display: none;
            }

            .search-btn {
                position: relative;

                input {
                    background-color: transparent !important;
                    color: #fff !important;
                    -webkit-text-fill-color: #fff !important;
                    border: 0;
                    padding: var(--input--padding-block)
                        var(--input--padding-inline) var(--input--padding-block)
                        0;
                    border-bottom: 1px solid #fff;
                    width: 98%;
                    @extend %type-h2;
                    font-size: 40px !important;
                    &:focus, &:focus-visible {
                        outline: 3px dotted;
                    }
                }

                input:-webkit-autofill::first-line {
                    font-size: 40px !important; 
                }

                input:-webkit-autofill {
                    -webkit-text-fill-color: #fff !important;
                    font-size: 40px!important;
                }

                input:-webkit-autofill {
                    transition: background-color 5000s ease-in-out 0s;
                    color: #fff !important;
                }

                button {
                    position: absolute;
                    top: 50%;
                    background-color: transparent !important;
                    color: #fff;
                    height: 54px;
                    border: 0;
                    right: 0;
                    width: auto;
                    padding: 0;
                    transform: translate(0, -50%);

                    &::after {
                        width: 40px;
                        height: 40px;
                        mask-image: url("../images/Search_arrow.svg");
                    }

                    &:hover {
                        background-color: var(--color--black);
                        color: var(--color--white);
                    }
                }
            }
        }
    }
}

.searchDialog-dialog-is-open {
    .site-header__header-menu {
        opacity: 0;
    }

    .hamburger-icon {
        rect {
            opacity: 1 !important;
        }
    }

    .btn-hamburger,
    .btn-basket,
    .btn-account {
        opacity: 1 !important;
    }
}

.inner-content {
    height: 100%;
    padding-top: 0;
}


// smooth transition
.menu-dialog-container {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 600ms ease, visibility 600ms ease;
    transform: translateX(0);
    @include mq(m) {
        transform: translateX(100%); 
    }
}

.menuDialog-dialog-is-open #menuDialog {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    @include mq(m) {
        transform: translateX(0); 
    }
}

.searchDialog-dialog-is-open #searchDialog {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    @include mq(m) {
        transform: translateX(0); 
    }
}