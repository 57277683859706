@use "../base" as *;

.shuffle-sizer-grid {
    gap: 0;
}

[data-shuffle-item] {
    padding-bottom: rfs(16, 20);
    width: var(--shuffle-item-width, 100%);

    @include mq(m) {
        width: var(--shuffle-item-width, 50%);
        padding-right: rfs(16, 20);
    }

    @include mq(l) {
        width: var(--shuffle-item-width, 33%);
    }
}
