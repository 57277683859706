/// Create a context where items are stacked vertically with a set gap.
///
/// @example scss
///    @include grid;
///
/// @access public

%stack,
:where(.stack) {
    > * {
        // Remove default margins (especially for first and last items)
        margin-block: 0;
    }

    > * + * {
        margin-block-start: var(--space--stack);
    }
}


%stack--xs,
:where(.stack--xs) {
    > * + * {
        --space--stack: var(--space--xs);
    }
}

%stack--s,
:where(.stack--s) {
    > * + * {
        --space--stack: var(--space--s);
    }
}

%stack--m,
:where(.stack--m) {
    > * + * {
        --space--stack: var(--space--m);
    }
}

%stack--l,
:where(.stack--l) {
    > * + * {
        --space--stack: var(--space--l);
    }
}

%stack--xl,
:where(.stack--xl) {
    > * + * {
        --space--stack: var(--space--l);
    }
}

%stack--layout,
:where(.stack--layout) {
    > * + * {
        --space--stack: var(--space--layout);
    }
}
