.img-fit {
    position: relative;
    display: block;
    overflow: hidden;

    > * {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        object-fit: var(--img-fit--object-fit, cover);
    }
}

.img-fit--contain {
    --img-fit--object-fit: contain;
}
