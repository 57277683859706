@use "sass-mq" as *;
@use "../base" as *;

.tn-component__fieldset-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    margin: var(--space--m) 0 var(--space--l);

    .tn-component__fieldset-label {
        @extend %type-body;
        @extend %bold;
        margin-bottom: 0;
    }

    .tn-component__radio-group {
        display: flex !important;
        align-items: center;
        gap: var(--space--layout--xs);

        .ng-scope {
            display: flex;
            align-items: center;
            gap: var(--space--xs);

            [type="radio"] {
                @extend %checkbox-input;
                border-radius: 0;

                &::before {
                    background-color: var(--color--black);
                    border-radius: 0;
                }
            }
        }
    }
}
