@mixin unbuttonify {
    font-family: var(--ff--secondary);
    background-color: transparent;
    border: 0;
    border-radius: 0;
    appearance: none;
    padding: 0;
    cursor: pointer;
    appearance: none;
    color: currentColor;
}

@mixin sr-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: fixed;
        top: 30px;
        left: 20px;
        white-space: normal;
        width: auto;
    }
}

@mixin interact {
    &:focus,
    &:active {
        @content;
    }

    &:hover {
        @media (hover: hover) {
            @content;
        }
    }
}

@mixin no-scrollbar {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    // Stop bottom pixel being cut off on some browsers
    padding-bottom: 1px;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin unstyled-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style-image: none;
        margin-bottom: 0;

        &::after {
          content: none;
        }
    }
}
