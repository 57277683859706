@use "sass-mq" as *;
@use "../base" as *;

.event-header {
    padding: var(--space--m) 0 0;
    background-color: var(--color--green);
    color: var(--color--black);
    overflow: hidden;

    .container {
        padding: 0;

        @include mq(l) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    @include mq(l) {
        padding: var(--space--xl) 0 0;
    }

    @include mq(xl) {
        padding: var(--space--xl) 0;
    }

    &-title {
        margin: 0 0 6px;

        @include mq(l) {
            margin: 0 0 var(--space--m);
        }
    }

    &__location {
        display: flex;
        align-items: baseline;
        gap: var(--space--xs);
        margin-bottom: var(--space--s);

        @include mq(l) {
            margin-bottom: 0;
            align-items: center;
        }

        .desktop {
            display: none;

            @include mq(m) {
                display: block;
                flex-shrink: 0;
            }
        }

        svg {
            flex-shrink: 0;

            &.mobile {
                margin-top: 0;

                @include mq(m) {
                    display: none;
                }
            }
        }

        &-title {
            @extend %type-body;
            margin: 0;
            text-wrap: pretty;

            @include mq(m) {
                font-size: calc(
                    ((22 / 16) * 1rem) + (24 - 22) * var(--fluid--bp)
                );
                line-height: 1.18;
                font-weight: var(--fw--bold);
                font-family: "aktiv-grotesk-extended";
            }
        }
    }

    &__flex {
        padding-bottom: 32px;
        justify-content: space-between;

        @include mq(l) {
            display: flex;
            padding-bottom: 24px;
        }

        @include mq(xl) {
            margin-bottom: var(--space--20);
            border-bottom: 1px solid;
        }

        &-inner {
            display: flex;
            flex-direction: column-reverse;
            padding: 0 var(--space--m);

            @include mq(l) {
                padding: 0;
                flex-direction: column;
            }
        }

        &-inner-mobile {
            padding: 0 var(--space--m);

            @include mq(l) {
                padding: 0;
                width: 400px;
                flex-shrink: 0;
            }

            .btn {
                font-size: 20px;

                &.coming-soon {
                    color: var(--500, rgba(127, 124, 124, 1));
                    pointer-events: none;
                    border: 1px solid var(--500, rgba(127, 124, 124, 1));
                    background: var(--50, rgba(245, 245, 246, 1));
                }
            }

            a {
                font-weight: 700;
            }

            p {
                margin: 8px 0 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__date {
        margin: 0 0 28px;
        text-wrap: wrap;

        @extend %bold;

        @include mq(l) {
            margin: 0 0 var(--space--s);
        }

        span {
            font-weight: 400;

            @include mq(l) {
                font-weight: 700;
            }
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        gap: var(--space--20);

        @include mq(l) {
            display: flex;
        }

        &-item {
            margin-bottom: var(--space--20) !important;

            @include mq(l) {
                margin-bottom: 0;
                padding-right: var(--space--20);
                flex-shrink: 0;
            }

            .event-header__list-title {
                @extend %bold;
                margin: 0 0 4px !important;
            }

            &:nth-child(3) {
                flex-shrink: initial;
            }
        }

        p {
            margin: 0 0 8px;
        }
    }

    &__details {
        padding: 0 var(--space--m);

        @include mq(l) {
            padding: 0;
        }

        @include mq(xl) {
            display: flex;
            gap: var(--space--20);
        }

        &-item {
            margin-bottom: var(--space--20) !important;
            flex-shrink: 0;
            
            @include mq(xl) {
                margin-bottom: 0;
                padding-right: var(--space--20);
            }

            p {
                margin: 0 0 4px;
            }

            .event-header__list-title {
                font-weight: var(--fw--bold);

                // &.two {
                //     margin-top: var(--space--s) !important;
                // }
            }
        }
    }

    &-mobile {
        .container {
            @include mq(l) {
                padding: 0;
            }
        }
    }

    .accordion-item {
        &::after {
            content: " ";
            display: block;
            position: absolute;
            border-top: 1px solid;
            top: -1px;
            width: 3000px;
            right: -3000px;
        }

        &__header {
            padding: var(--space--m);

            @include mq(l) {
                padding: var(--space--m) 0;
            }
        }

        &__heading {
            font-weight: var(--fw--bold);
            font-family: "aktiv-grotesk-extended";
        }

        &__content {
            padding: 0;

            &-panel {
                padding: 0 var(--space--m);

                @include mq(l) {
                    padding: 0;
                }
            }
        }
    }
}

// Event instances

.event-instances {
    .container {
        padding: 0;

        @include mq(m) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    &__title {
        margin: 0 0 var(--space--m);

        @extend %type-h1;

        padding: var(--space--m);

        @include mq(m) {
            padding: 0;
        }
    }

    &__items {
        gap: 0;
    }

    .event-instance {
        border-bottom: 1px solid;
        padding: 16px;

        .date-time {
            margin: 0;
            padding-top: 6px;

            @extend %bold;

            span {
                font-weight: 400;
            }
        }

        &.hidden-instance {
            display: none;
        }
    }
}

// Page specific
.single-event {
    .main-content__text.text-expand {
        --text-expand--constrained-height: 11lh;
    }

    .explore-block {
        margin-top: var(--space--l);

        @include mq(l) {
            margin-top: 0;
        }
    }

    .page-header__image {
        position: relative;

        &::after {
            content: "";
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            height: 290px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}
