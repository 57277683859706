@use "sass-mq" as *;

%container,
.container {
    width: 100%;
    max-width: var(--width--max);
    padding-inline: var(--container--padding-inline);
    margin-inline: auto;

    @include mq(m) {
        padding-inline-start: 48px;
        padding-inline-end: 78px;
    }

    &::before,
    &::after {
        content: none;
    }
}

%container-tnew,
.container-tnew {
    @extend %container;

    max-width: var(--width--m);
}

.inner-wrapper,
%inner-wrapper {
    max-width: var(--width--m);
    position: relative;
}

.inner-padding {
    @include mq(m) {
        padding-right: 80px;
    }
}
