@use "sass-mq" as *;
@use "../base" as *;

.tn-events-detail-page {
    .tn-prod-season-header {
        display: none;
    }

    .tn-event-detail {
        display: block;
        max-width: var(--width--m);

        &__additional-events-container {
            width: 100%;
            background: transparent;
            margin: 0;
            padding: 0;

            .tn-additional-events {
                // margin: var(--space--xl) 0 0; might be needed
                margin: 0;

                &__controls-container {
                    margin: 0;
                    border-bottom: 0;
                    padding: 0;
                }

                &__continue-shopping-link,
                &__instructions {
                    display: none;
                }

                &__date-select {
                    padding: 0 24px;
                    border: 0;
                    max-width: auto;
                    width: 100%;
                    appearance: none;
                    background: none;
                    min-width: auto;
                    margin: 0;
                    position: relative;

                    @extend %type-body-l;
                    font-weight: var(--fw--bold);

                    background: url("https://oae-refresh-development.cogdesign.com/wp-content/themes/oae/public/images/calendar_today.svg");
                    background-repeat: no-repeat;
                    background-position: 93% 50%;
                    background-size: 24px;
                    max-width: 400px;
                }

                .input-group {
                    @extend %btn--mask-icon-after;
                }

                .input-group-btn {
                    .btn {
                        height: 56px;
                    }
                }
            }
        }

        &__performance-details-container {
            margin-bottom: var(--space--xl);
            display: flex;
            flex-direction: column;

            .sr-only {
                display: none;
            }
        }

        &__title {
            margin: 0 0 var(--space--m);
            order: -1;

            @include mq(m) {
                margin: 0 0 var(--space--xl);
            }
        }

        &__display-time {
            margin: 0 0 var(--space--m);
            font-weight: var(--fw--bold);

            @include mq(m) {
                margin: 0;
            }
        }

        .tn-ticket-selector {
            max-width: initial;

            &__zone-selector-container {
                ul {
                    display: block;

                    li {
                        padding-bottom: 12px;

                        label {
                            padding-left: 0;
                            display: flex;
                            align-items: center;
                            gap: 12px;

                            input {
                                display: block;
                                margin-right: 24px;
                                position: relative;
                            }
                        }
                    }
                }
            }

            &__pricetype-selector {
                margin: 0;
            }

            &__controls-container {
                margin-bottom: var(--space--xl);
            }

            &__pricetype-zone-heading {
                @extend %type-h4;
                display: block;
                position: relative;
                width: 100%;
                height: auto;
                overflow: visible;
                clip: unset;
                font-weight: var(--fw--bold);
                margin-bottom: var(--space--20);
            }

            &__pricetype-list {
                margin: var(--space--l) 0 0;
                display: block;

                &-item {
                    border: 0;
                    background: var(--color--neutral-100);
                    padding: 16px 16px 32px;

                    .form-group,
                    .tn-ticket-selector__pricetype-select-wrapper {
                        label {
                            display: none;
                        }
                    }
                }
            }

            &__pricetype-name {
                @extend %type-body-l;
            }
        }

        // Best seating button
        .tn-ticketing-mode-change {
            margin: 0 0 var(--space--xl);

            &__content {
                width: auto;
                display: inline-block;
                font-style: normal;
                padding: 0;
                border: 0;
                background: transparent;
            }

            &__anchor {
                @extend %btn--alt;
                text-decoration: none;
                text-align: center;
                font-size: 16px;
                color: #000;
                padding: 10px 11px;
                @include mq(m) {
                    font-size: 20px;
                    padding: 10px 34px;
                }

            }
        }

        // Seating block
        .tn-syos {
            margin-bottom: 0;

            &__layout-container {
                border: 0;
                background-color: transparent;
            }

            &-screen-container {
                &__button-list-container {
                    background-color: var(--color--neutral-100);
                    border: 0;
                    padding: 0;

                    @include mq(l) {
                        width: 348px;
                        padding: var(--space--l);
                    }
                }

                &__button-list-heading {
                    @extend %type-h4;
                    @extend %bold;
                    margin-bottom: var(--space--xl);
                }

                &__button-list {
                    margin-bottom: 0;

                    &-item {
                        width: 100%;
                    }
                }

                &__map-container {
                    background-color: var(--color--neutral-100);
                }
            }

            &__screen-view-components {
                position: relative;
                border: 24px solid var(--color--neutral-100);
            }

            &-btn-view-screens {
                right: var(--space--l);
                left: auto;
                top: var(--space--l);

                @extend %btn--alt;
                padding: 10px var(--space--l);
                background-color: transparent;
            }

            &-legend {
                padding: 24px;

                &__description-term {
                    svg {
                        top: 5px;
                    }
                }

                &__container {
                    position: absolute;
                    left: 0;
                    bottom: auto;
                    top: 24px;

                    @include mq(l) {
                        top: 0;
                        bottom: 0;
                    }
                }

                &__btn-show-legend {
                    left: var(--space--layout--xs);
                    right: auto;
                    position: absolute;
                    top: 24px;
                    left: 24px;

                    @extend %btn--alt;
                    padding: 10px var(--space--l);
                    background-color: transparent;
                    display: none;

                    @include mq(l) {
                        display: flex;
                        width: auto;
                        white-space: nowrap;
                    }

                    &-mobile {
                        top: 0;
                        bottom: auto !important;
                        align-items: center;
                        border: 1px solid var(--color--black);
                        display: flex;
                        font-family: var(--ff--primary);
                        gap: 10px;
                        height: 53px;
                        padding: 10px 34px;
                        transition: all .5s ease;
                        z-index: 1;
                        white-space: nowrap;
                        font-family: var(--ff--primary);
                        font-weight: 700 !important;
                        font-size: 20px;
                        left: 0 !important;
                        top: 44px;
                        width: 233px;
                        text-align: center !important;
                        justify-content: center;

                        @include mq(m) {
                            top: 0;
                            left: 24px !important;
                        }

                        @include mq(l) {
                            display: none;
                        }
                    }
                }

                &__zone-list {
                    margin: 0;
                    border: 0;
                    padding: 0;
                }

                &__zone-label {
                    @extend %type-body-l;
                    @extend %bold;
                    margin-bottom: 0;
                }

                &__zone-price-range {
                    @extend %type-body-l;
                    margin-bottom: 4px;
                }

                &__zone-pricetype-list {
                    display: block;
                    margin: 0;

                    &-item {
                        margin: 0;
                        border: 0;
                        padding: 0;

                        @extend %type-body;
                    }
                }

                &__description {
                    &-group {
                        margin: 0 0 8px;
                        padding-bottom: 8px;
                        border-bottom: 1px solid;
                    }
                }

                &__desktop {
                    &-heading {
                        @extend %type-h4;
                        @extend %bold;
                    }

                    &-heading-wrapper {
                        background-color: transparent;
                        padding: var(--space--l);
                        padding-right: 0;
                        height: auto;
                        display: flex;
                        align-items: center;
                        border-bottom: 0;
                    }
                }

                &__legend-drawer {
                    width: 310px;
                    background: var(--color--white);
                    right: auto;
                    left: var(--space--l);
                    border: 0;
                    z-index: 10;
                    top: var(--space--l);
                    bottom: var(--space--l);
                    left: -334px;
                    transition: left .25s ease-out;
                }

                &__seat-type-list {
                    margin: var(--space--l) 0 0;

                    .tn-syos-legend__description-group {
                        margin-bottom: var(--space--l);
                        padding-bottom: var(--space--l);

                        .tn-syos-legend__description-details {
                            @extend %type-body-l;
                            @extend %bold;
                            margin-bottom: 0;
                        }
                    }
                }

                &::after {
                    padding-bottom: 0;
                }
            }

            &-mobile-cart-controls {
                height: auto;
                background-color: var(--color--green);
                margin-top: var(--space--xl);
                justify-content: space-between !important;
                display: block;

                @include mq(m) {
                    display: flex;
                }

                &__btn-view-cart {
                    background: var(--color--black);
                    color: var(--color--white);
                    border: 0;
                    width: 60px;
                    padding: 8px;

                    &-label {
                        font-size: 12px;
                        font-weight: var(--fw--bold);
                        padding: 0;
                    }
                }

                &__summary {
                    height: auto;
                    border: 0;
                    background: transparent;
                    flex: none;
                    padding: 0;
                    display: flex;
                    flex-direction: column-reverse;
                    margin: 0 auto 0 0;

                    &-seat-count {
                        @extend %type-body;
                        margin-bottom: var(--space--xxs);
                        text-align: left;
                        opacity: 1;
                    }

                    &-subtotal {
                        @extend %type-h5;
                        @extend %bold;
                        margin: 0 0 var(--space--m);
                        text-align: left;
                        opacity: 1;

                        @include mq(m) {
                            margin: 0;
                        }
                    }
                }
            }

            &-cart {
                border: 0;

                &__seats-list {
                    margin: 0;
                    box-shadow: none;
                    padding: 0;
                }

                &-seat {
                    width: 100%;
                    padding: var(--space--m) var(--space--l);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &__list {
                        flex-wrap: nowrap;
                        margin: 0;
                    }

                    &__btn-remove {
                        position: relative;
                        top: 0;
                        color: #e80000;
                        text-transform: inherit;

                        @extend %type-body-l;
                        @extend %bold;
                        margin: 0;
                    }
                }

                &__heading {
                    @extend %type-h5;
                    @extend %bold;
                    margin-bottom: var(--space--m);
                }

                &__content-container {
                    padding: 0 24px 24px;
                    background: var(--color--green);
                    background-color: var(--color--green);
                }

                &__summary {
                    padding: 0;
                    margin-top: var(--space--l);

                    &-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    &-label {
                        @extend %type-h5;
                    }

                    &-value {
                        @extend %type-h5;
                        @extend %bold;
                    }

                    .tn-syos__btn-add-to-cart {
                        display: none;
                    }
                }
            }

            &-screen-button {
                width: 100%;
                height: 43px;

                &__section-heading {
                    font-size: 14px;
                    line-height: 14px;
                    padding: 0;
                    margin: 0;
                    border: 0;
                    flex-shrink: 0;

                    @extend %bold;

                    @include mq(m) {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }

                span {
                    font-size: 16px;
                    line-height: 16px;
                    flex-shrink: 0;

                    @extend %bold;
                    margin-bottom: 0;
                }
            }

            &-seat-container {
                // width: 100%;
                margin: 0 0 0 auto;
                width: 100%;

                @include mq(l) {
                    width: calc(100% - 300px);
                }
            }
        }

        .tn-is-contiguous-seats-requested-label {
            padding-left: 0;
            display: flex;
            align-items: center;
            gap: 12px;

            input {
                display: block;
                margin-right: 24px;
                position: relative;
            }
        }
    }

    .tn-syos-price-type-selector__content-container {
        box-shadow: none;
        display: table;
        z-index: 1;
        max-width: 400px;
        margin: auto;
        padding: 16px 12px;
        border: 1px solid;
        .tn-syos-seat-info__header {
            position: relative;
            background-color: transparent;
            padding: 0 0 16px;
            border-bottom: 1px solid #000;
            display: flex;
            flex-direction: column-reverse;
            .tn-modal__btn-close {
                position: relative;
                right: 0;
                top: 0;
                opacity: 1;
                margin: 0 0 32px auto;
            }
            table {
                margin: 0;
            }
        }
        h2.tn-syos-seat-info__price-type-heading {
            padding: 16px 0 0;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 8px;
        }

        .tn-syos-seat-info__price-type-list {
            padding: 10px 0 0;
            margin: 0;
            &-item {
                width: 100%;
                /* display: flex; */
            }
            .tn-syos-seat-info__price-type--button {
                flex-wrap: wrap;
            }
            .tn-syos-seat-info__price-type-price {
                text-align: center;
            }
        }
    }

    .tn-syos--legend-details-visible {
        .tn-syos-legend__legend-drawer {
            left: 14px;
        }
    }

    .tn-ticket-selector__zone-selector-container {
        display: none;
    }
}
