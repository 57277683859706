@use "sass-mq" as *;
@use "../base" as *;

.tn-payment-page {
    form {
        @include mq(m) {
            width: var(--width--m);
        }
    }

    .tn-component-contact-permissions,
    .tn-donation-ask-component,
    .tn-cart-component,
    .tn-patron-billing-information,
    .tn-gift-certificates-redemption-component,
    .tn-patron-shipping-information {
        background: var(--color--neutral-100);
        padding: var(--space--layout--xs) var(--space--20);
        width: 100%;
        margin-bottom: var(--space--20);

        @include mq(m) {
            padding: var(--space--layout--xs);
            margin-bottom: var(--space--xl);
            width: var(--width--m);
        }

        a,
        a span {
            color: var(--color--black) !important;
        }

        // Contact Prefs
        &__header {
            margin: 0;
        }

        &__settings__item {
            .tn-component__fieldset-radio {
                .tn-component__radio-group {
                    margin: 0;
                    display: flex !important;
                }
            }
        }

        &__footer-actions {
            text-align: left;
            display: flex;
            gap: 20px;
            justify-content: flex-end;
            margin-top: var(--space--20);
        }

        // Billing info
        .tn-line1,
        .tn-line2,
        .tn-line3,
        .tn-line4,
        .tn-line5,
        .tn-electronic-address,
        .tn-phone {
            @extend %type-body-l;
            margin-bottom: 10px;
        }

        .tn-phone {
            margin-bottom: 26px;
        }

        // ticket Delivery
        .tn-selected-shipping-method {
            @extend %type-h5;
            @extend %bold;
            margin-bottom: 26px;
        }

        // Basket
        h2 {
            position: relative;
            width: 100%;
            height: auto;
        }

        .tn-cart__section {
            h3 {
                position: relative;
                width: 100%;
                height: auto;

                @extend %type-body;
                @extend %type-body-l;
                margin-bottom: var(--space--m);
            }

            .tn-cart-item-detail__list {
                border: 0;
                padding: 0;
            }
        }

        .tn-cart-totals {
            margin-bottom: 0 !important;
            border-top: 1px solid;
            padding-top: 16px;

            .sr-only {
                display: none;
            }
            // h2 {
            //     position: absolute;
            // }

            &__line-item--total {
                margin-bottom: 0 !important;
            }
        }
    }

    .tn-order-address-information-component {
        margin-bottom: 0px !important;
    }

    .tn-patron-billing-information,
    .tn-patron-shipping-information {
        padding: 0 !important;
        background: transparent !important;

        .col-xs-12,
        .col-sm-6 {
            background: var(--color--neutral-100);
            padding: var(--space--layout--xs) var(--space--20);
            margin-bottom: var(--space--20);

            @include mq(m) {
                padding: var(--space--layout--xs);
                margin-bottom: var(--space--xl);
            }
        }
    }

    //Promo code
    .tn-gift-certificates-redemption-component {
        .form-group {
            margin-bottom: var(--space--20);
        }
    }

    // forms width
    .tn-payment-component,
    .tn-gift-certificates-redemption-component,
    .tn-donation-ask-component {
        .col-xs-12 {
            padding: 0;
        }

        form {
            margin: 0;
            width: 100%;
        }
    }

    // Payment button block
    .tn-payment-component {
        input {
            display: none;
        }

        .tn-payment-submit-component {
            max-width: 100%;
            padding-right: 0;
            margin: 0 0 var(--space--l);
            display: flex;
            justify-content: flex-end;
        }
    }

    .tn-page-bottom {
        margin: 0;
    }
}
