@use "../base" as *;

.credits {
    background-color: var(--color--neutral-950);
    padding: var(--space--l) 0 var(--space--m);
    color: var(--color--white);

    @include mq(m) {
        padding: var(--space--layout--s) 0;
    }

    .container {
        padding-inline: 0;

        @include mq(m) {
            padding-inline: var(--container--padding-inline);
        }
    }

    h2 {
        @extend %type-h1;
        padding-bottom: var(--space--l);
        margin-bottom: var(--space--20);
        border-bottom: 1px solid var(--color--white);
        position: relative;

        @include mq(m) {
            padding-bottom: var(--space--xl);
        }

        &::before {
            content: " ";
            display: block;
            position: absolute;
            border-top: 1px solid;
            bottom: -1px;
            width: 3000px;
            left: -3000px;
        }
    }

    &__grid {
        @include mq(m) {
            column-count: 3;
            column-gap: 20px;
        }
    }

    .grid-item {
        break-inside: avoid;
    }

    &__section-title {
        margin: 0;

        @extend %bold;
    }

    &__list {
        margin: 0 0 var(--space--s);
        list-style: none;
        padding: 0;

        &-item {
            @extend %type-body-l;
        }
    }

    .section__heading {
        padding: 0 var(--space--m) 24px;
        margin-bottom: 0;
    }

    // Mobile Styling
    .accordion-item {
        &__header {
            padding: 16px 16px 0;

            .accordion-item__icon::before {
                background-color: var(--color--white);
            }

            &[aria-expanded="true"] {
                + .accordion-item__content-panel {
                    height: 400px !important;
                }
            }
        }

        &__heading {
            @extend %type-h5;
            @extend %bold;
            margin: 0;
        }

        &__content {
            padding: var(--space--m);
            height: 100%;
            overflow: scroll;

            // &-panel {
            //     height: 700px !important;
            // }

            p {
                @extend %type-body-l;
                @extend %bold;
                margin: 0px 0 var(--space--s);
            }

            ul {
                margin: 0 0 20px;
                padding: 0;
                list-style: none;

                li {
                    @extend %type-body;
                }
            }
        }
    }
}
