@use "../base" as *;

.quote {
    .content {
        background: var(--color--white);
        padding: var(--space--l) var(--space--m);

        @include mq(m) {
            padding: var(--space--layout--xs) var(--space--layout--m)
                var(--space--xl) var(--space--layout--xs);
        }

        h3 {
            margin-bottom: var(--space--m);
        }

        p {
            margin: 0;

            @extend %type-body-l;

            @include mq(m) {
                font-weight: var(--fw--bold);
            }
        }

        &.content-margin {
            margin-top: 0px;
            padding: var(--space--m);

            @include mq(m) {
                padding: 64px var(--space--layout--m) var(--space--xl)
                    var(--space--layout--xs);
            }
        }
    }

    &.text-image {
        p {
            @extend %type-body-l;
            font-weight: normal;
            margin: 0 0 18px;

            @include mq(m) {
                margin: 0 0 var(--space--20);
            }

            a {
                font-weight: var(--fw--bold);
            }
        }
    }
}
