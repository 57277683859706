@use '../0-helpers/' as *;

.event-access-badges {
    @include unstyled-list;

    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.event-access-badge {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: var(--space--base);

    &__icon {
        max-width: 35px;
    }
}
