@use "../base" as *;

.account-hub {
    &__call-to-action {
        position: relative;
        padding: var(--space--xl);
        margin-block: var(--space--xl);
        background-color: var(--color--neutral-950-300);
        border-radius: var(--border-radius--m);

        .btn {
            margin-top: var(--space--s);
        }
    }
}

.account-links {
    @extend %flex-grid;

    --grid--columns: 1;

    margin-block: var(--space--xl);

    @include mq(m) {
        --grid--columns: 2;
    }
}

.account-link {
    position: relative;
    padding: var(--space--xl);
    background-color: var(--color--neutral-950-100);
    border-radius: var(--border-radius--m);

    &__heading {
        @extend %type-h4;
    }

    &__fill-link {
        &::before {
            content: "";
            inset: 0;
            position: absolute;
            z-index: 1;
        }
    }
}
