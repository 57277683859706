@use "../base" as *;

.page-header--discover {
    .post-header__heading {
        margin: 0 0 var(--space--m);
    }

    h4 {
        margin: 0 0 48px;
        padding: 0 var(--space--m);

        @include mq(m) {
            padding: 0;
        }
    }

    .top-filters {
        border-bottom: 0 !important;
        margin-bottom: 0;
    }
}

.page-template-discover {
    .feed_cards {
        --grid--columns: 1;

        @include mq(m) {
            --grid--columns: 2;
        }

        @include mq(xl) {
            --grid--columns: 3;
        }
    }

    .filter-blog-type {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0 0 50px;
        gap: var(--space--m);
        align-items: flex-start;
        max-width: 600px;
        padding: 0 var(--space--m);

        @include mq(m) {
            padding: 0;
            margin: 0;
        }

        .filter-category__list-item {
            display: flex;
            align-items: center;
            gap: 8px;

            &.desktop {
                display: none;

                @include mq(m) {
                    display: block;
                }
            }

            input {
                display: block;

                &::before {
                    background: transparent !important;
                }

                &::after {
                    color: var(--color--black);
                }
            }

            label {
                @extend %type-body-s;
                height: 24px;
                border: 0;
                padding: 0;
                background: transparent !important;
                color: var(--color--white) !important;
                font-weight: normal !important;
            }
        }
    }

    .surprise-btn {
        display: flex;
        align-items: center;
        gap: 8px;

        @extend %type-h5;
        @extend %bold;
        font-size: 16px;

        border: 1px solid;
        width: 100%;
        height: 40px;
        padding: 0 24px;
        justify-content: space-between;
        margin: 0 0 16px;

        @include mq(m) {
            border: 0;
            width: auto;
            height: auto;
            padding: 0;
            padding-left: 8px;
            justify-content: flex-start;
            margin: 0;
        }

        span {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            padding-top: 4px;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .mobile-button {
        padding: 0 var(--space--m);

        @include mq(m) {
            display: none;
        }
    }

    .filter-category {
        flex-shrink: 0;

        @include mq(m) {
            width: 62%;
        }

        &__list {
            max-width: 100%;
        }
    }
}
