@use "../base" as *;

.job-header {
    padding: var(--space--xl) 0;
    color: var(--color--white);

    @include mq(m) {
        padding: var(--space--xl) 0 var(--space--layout--xs);
    }

    .post-header__tags-item {
        background-color: var(--color--white);
    }

    h5 {
        margin: 0;

        @include mq(m) {
            margin: 0 0 var(--space--l) !important;
        }
    }
}

.job-details {
    background-color: var(--color--green);
    padding: var(--space--m) 0 var(--space--l);

    @include mq(m) {
        padding: var(--space--xl) 0;
    }

    .flex-wrap {
        @include mq(m) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__list {
        list-style: none;
        color: var(--color--black);
        padding: 0;
        margin: 0 0 var(--space--20);

        @include mq(m) {
            display: flex;
            gap: var(--space--xl);
            margin: 0;
        }

        &-item {
            margin: 0 0 var(--space--s);

            @include mq(m) {
                margin: 0;
            }

            h5 {
                @extend %bold;
                margin: 0;
                margin-bottom: 4px;
            }

            p {
                margin: 0;
            }
        }
    }

    &__link {
        background-color: var(--color--black);
        padding: var(--space--m) var(--space--s);
        text-decoration: none;
        display: flex;
        gap: 8px;
        color: var(--color--white);
        transition: all 500ms ease;

        @include mq(l) {
            width: 400px;
        }

        &-title {
            margin: 0 0 4px;

            @extend %type-h5;
            @extend %bold;
        }

        svg {
            path {
                fill: var(--color--white);
            }
        }

        &:hover {
            background-color: var(--color--white);
            color: var(--color--black);

            svg {
                path {
                    fill: var(--color--black);
                }
            }
        }
    }
}

// page specific
.single-job {
    .explore-block {
        margin-top: var(--space--l);

        @include mq(l) {
            margin-top: 0;
        }
    }
}
