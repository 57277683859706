@use "../base" as *;
@use "sass:list";

/// Generate color helper classes from the $colors map
/// .has-#{$color}-background-color classes create a color context, setting the foreground
/// color as well as any other color properties that are provided in the color map.

/// .has-#{$color}-color classes will set the text color to the 'foreground' color specified in the color map.
@each $color-map in $colors {
    $name: #{list.nth($color-map, 1)};
    $property: "--color--#{$name}";

    %has-#{$name}-background-color,
    .has-#{$name}-background-color {
        @include color-context(#{$name}, $colors);
    }

    %has-#{$name}-color,
    .has-#{$name}-color {
        color: var(#{$property});
    }
}
