@use "../base" as *;

.people-component {
    h2 {
        @extend %type-h2;
        margin: 0 0 28px;

        @include mq(m) {
            margin: 0 0 var(--space--xl);
        }
    }

    &__cards {
        --grid--column-gap: var(--space--m);
        --grid--columns: 2;
        --grid--row-gap: var(--space--m);

        @include mq(m) {
            --grid--columns: 3;
            --grid--column-gap: var(--space--20);
            --grid--row-gap: var(--space--20);
        }

        @include mq(l) {
            --grid--columns: 4;
        }
    }
}
