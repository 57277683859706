@use "../base" as *;

.promo-block,
.support-block,
.quote {
    img {
        aspect-ratio: 16/9;
        object-fit: cover;
    }

    .content {
        background: var(--color--green);
        padding: var(--space--l) var(--space--m);
        z-index: 1;
        position: relative;

        @include mq(m) {
            margin-right: 105px;
            margin-top: -64px;
            padding: var(--space--20) var(--space--layout--m) var(--space--xl)
                var(--space--layout--xs);
        }

        h3 {
            margin-bottom: var(--space--xs);
        }

        p {
            margin: 0 0 var(--space--m);

            @extend %type-body-l;

            @include mq(m) {
                margin: 0 0 var(--space--l);
            }
        }

        .btn--alt {
            @include mq(m) {
                width: auto;
                display: inline-flex;
            }
        }
    }
}
