@use "sass-mq" as *;
@use "../base" as *;

.tn-contribution-individual-page {
    .tn-page-top {
        h2 {
            u {
                text-decoration: none;

                span {
                    b {
                        @extend %type-h2;
                    }
                }
            }
        }
    }

    .tn-contribution-component {
        @include mq(m) {
            width: var(--width--m);
        }

        .tn-contribution-type-text {
            ul {
                padding-left: 30px;

                li {
                    @extend %type-body-l;
                }

                i {
                    font-weight: 400;
                    font-style: normal;
                    margin-top: 24px;

                    @extend %type-body;
                    margin-left: -30px;
                }

                br {
                    display: none;
                }

                p {
                    margin-left: -20px;

                    @extend %type-body-xs;
                }
            }
        }

        .row {
            background: var(--color--neutral-100);
            padding: var(--space--layout--xs) var(--space--20);
            width: 100%;

            @include mq(m) {
                padding: var(--space--layout--xs) var(--space--layout--xs)
                    var(--space--l);
                width: var(--width--m);
            }

            small {
                @extend %type-body-l;
                margin-left: 8px;
            }

            &:nth-last-of-type(1) {
                padding: 34px 24px;
                background: var(--color--green);
                text-align: right !important;
                align-items: center;
                justify-content: flex-start !important;
                gap: 20px;

                .col-xs-12.col-sm-6 {
                    width: 100%;
                    display: flex;
                    gap: 20px;
                    justify-content: flex-start;
                    flex-direction: row-reverse;
                }
            }

            &.tn-contribution-type-form-container {
                padding-top: 0;
                margin-bottom: var(--space--20);

                @include mq(m) {
                    margin-bottom: var(--space--xl);
                }

                .tn-contribution-type-form {
                    border-top: 1px solid;
                    padding: var(--space--l) 0;

                    h4 {
                        @extend %type-h4;
                        @extend %bold;
                        margin: 0 0 var(--space--l);

                        &.terms-title {
                            border-top: 1px solid;
                            margin: var(--space--l) 0;
                            padding-top: var(--space--l);
                        }
                    }

                    ul {
                        display: block;
                        padding-left: 30px;

                        li {
                            @extend %type-body-l;
                        }
                    }

                    font {
                        color: var(--color--black) !important;
                    }

                    .checkbox {
                        small {
                            display: none;
                        }

                        input {
                            display: block;

                            &::before {
                                background-color: transparent;
                            }
                        }

                        label {
                            display: flex;
                            align-items: center;
                            gap: var(--space--xs);

                            @extend %type-body;
                        }
                    }
                }
            }
        }
    }
}
