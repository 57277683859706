@use "sass-mq" as *;
@use "../base" as *;

.tn-plug-in-direct-debit-selection {
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        --grid--row-gap: 16px;

        li {
            padding-left: 12px;

            input {
                left: 0;
            }
        }
    }
}

.tn-plug-in-direct-debit {

    .col-sm-6,
    .col-sm-12 {
        padding: 0;

        .container {
            padding: 0 0 24px;
        }
    }

    .tn-cart-item {
        .col-xs-10 {
            padding: 0;
        }
    }

    .TNEW_steps_block {
        display: flex;
        gap: 24px;
    }

    .col-sm-3 {
        padding: 0 16px 0 0px;
    }
}

.has-feedback {

    .checkbox label,
    .radio label {
        padding-left: 32px;

        input {
            left: 0;
        }
    }

    .checkbox label input {
        display: block !important; 
    }
}

#tn-contribution-form,
#dd-second-member-form {
    .col-xs-12.col-sm-6 {
        padding: 0;
    }

    .tn-contribution-type-form {
        padding: var(--space--l) 0 !important;
    }
}

.tn-precart-response-message {
    table {
        &:last-of-type {
            tr {
                display: inline-block;

                &:last-of-type {
                    .btn {
                        margin-left: 16px;
                        background-color: transparent;
                    }
                }
            }
        }
    }


    .radio label {
        min-height: 20px;
        padding-left: 32px;
    }

    .tn-plug-in-direct-debit-page-actions {
        display: flex;
        gap: 24px;
    }
}