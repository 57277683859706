@use "sass-mq" as *;
@use "../base" as *;

.tnew-body {
    ul,
    ol {
        --grid--column-gap: 8px;
        --grid--row-gap: 8px;

        @extend %flex-list;

        padding-left: 0;
        list-style: none;
        margin-bottom: var(--space--l);

        li {
            margin: 0;

            label {
                margin-bottom: 0;
            }
        }
    }
}

.tn-syos-seat-info__price-type-list {
    padding: 10px;
    .tn-syos-seat-info__price-type--button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .tn-syos-seat-info__price-type-description {
            flex: 0 0 auto;
        }
    }
}
