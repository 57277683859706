@use "../0-helpers/" as *;
@use "../1-variables" as *;
@use "type-styles" as *;
@use "link-styles" as *;

// Generate button color palettes (e.g. .btn--neutral-950)
@each $palette, $props in $btn-colors {
    %btn--#{$palette},
    .btn--#{$palette} {
        @each $prop, $value in $props {
            $propname: "--btn--#{$prop}";

            #{$propname}: #{$value};
        }
    }
}

%btn,
.btn {
    @extend %type-button;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--btn--padding-block) var(--btn--padding-inline);
    font-family: var(--ff--primary);
    gap: #{rem(4)};
    font-weight: var(--btn--font-weight);
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border: var(--btn--border-width) solid var(--btn--border-color);
    border-radius: var(--btn--border-radius, 0px);
    color: var(--btn--color);
    transition: all var(--transition--base);
    transition-property: background-color, color, border-color, box-shadow,
        transform;
    transition: all 500ms ease;

    @include interact {
        background-color: var(--btn--background-color--hover);
        color: var(--btn--color--hover);
        border-color: var(--btn--border-color--hover);
        text-decoration: none;
    }

    svg {
        width: var(--btn--icon-size, 1em);
        height: var(--btn--icon-size, 1em);
        transition: all 500ms ease;
    }

    &:disabled {
        @extend %btn--disabled;
    }
}

%btn--secondary-large,
.btn--secondary-large {
    @extend %type-button;

    background-color: var(--color--black);
    color: var(--color--white);
    height: 53px;

    @include interact {
        background-color: var(--color--white);
        color: var(--color--black);
        border-color: var(--btn--border-color--hover);
        text-decoration: none;
    }
}

%btn--disabled,
.btn--disabled {
    background-color: var(--color--white);
    // box-shadow: inset 0px 0px 0px 1px var(--color--disabled);
    color: var(--color--neutral-500);
    border-color: var(--color--neutral-500);
    pointer-events: none;
}

%btn--link,
.btn--link {
    @extend %link;

    background-color: unset;
    padding: unset;
    font-family: inherit;
    font-weight: var(--fw--bold);
    text-align: inherit;
    color: var(--link--color);
    border: 0;
    border-radius: 0;
    transition: none;
    text-decoration: var(--link--text-decoration);

    @include interact {
        color: var(--link--color--hover);
        text-decoration: var(--link--text-decoration--hover);
        background-color: unset;
    }
}

%btn--square,
.btn--square {
    padding: 0 0.3em;
    min-width: var(--btn--size, #{rem(44)});
    min-height: var(--btn--size, #{rem(44)});
    z-index: 1;
    border-radius: var(--btn--border-radius, 0px);

    svg {
        width: var(--btn--icon-size, 1em);
        height: var(--btn--icon-size, 1em);
        color: var(--color--white);
    }
}

%btn--mask-icon,
.btn--mask-icon {
    position: relative;
    overflow: hidden;

    &::before {
        width: var(--btn--icon-size, 1em);
        height: var(--btn--icon-size, 1em);
        background-color: currentColor;
        content: "";
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        // e.g. mask-image: url('../images/arrow.svg');
    }
}

%btn--mask-icon-after,
.btn--mask-icon-after {
    position: relative;
    overflow: hidden;

    &::after {
        width: var(--btn--icon-size, 1em);
        height: var(--btn--icon-size, 1em);
        background-color: currentColor;
        content: "";
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        // mask-image: url('../images/arrow.svg');
    }
}

// Reset .btn button styles
%btn-reset {
    background-color: unset;
    display: inline;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    font-family: inherit;
    gap: 0;
    font-weight: inherit;
    text-align: inherit;
    border: 0;
    border-radius: 0;
    background-color: unset;
    color: inherit;
    transition: none;

    @include interact {
        background-color: unset;
        color: inherit;
        border-color: unset;
        text-decoration: none;
    }
}

%btn--alt,
.btn--alt {
    padding: 10px 34px;
    min-width: var(--btn--size, #{rem(44)});
    min-height: var(--btn--size, #{rem(44)});
    z-index: 1;
    border: 1px solid var(--color--black);
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: var(--fw--bold);
    font-family: var(--ff--primary);
    height: 53px;
    transition: all 500ms ease;

    @extend %type-h5;

    svg {
        width: var(--btn--icon-size, 24px);
        height: var(--btn--icon-size, 24px);
    }

    &:hover {
        background: var(--color--black);
        color: var(--color--white);
    }
}

%btn--icon--arrow,
.btn--icon--arrow {
    &::after {
        mask-image: url("../images/arrow.svg");
    }
}
