@use "../0-helpers" as *;
@use "sass-mq" as *;
@use "type-styles" as *;

html {
    color: var(--color--foreground);
    background-color: var(--color--background);
    scroll-behavior: smooth;
    font-size: inherit;
}

body {
    @extend %type-body;

    display: flex;
    flex-direction: column;
    text-underline-offset: var(--text-underline-offset);
}

.page-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
}

// Type and Prose styles
:where(p, ul, ol, table, iframe, embed, hr, address, pre, dl, fieldset) {
    &:where(:not([class~="not-prose"] *)) {
        margin-block: var(--space--prose);
    }
}

// Set up default spacing for prose elements
:where(h1, h2, h3, h4, h5, h6) {
    text-wrap: balance;

    &:where(:not([class~="not-prose"] *)) {
        margin-block-start: var(--heading--margin-top);
        margin-block-end: var(--heading--margin-bottom);
    }
}

h1 {
    @extend %type-h1;
}

h2 {
    @extend %type-h2;
}

h3 {
    @extend %type-h3;
}

h4 {
    @extend %type-h4;
}

h5 {
    @extend %type-h5;
}

h6 {
    @extend %type-h6;
}

strong,
b {
    font-weight: var(--fw--bold);
}

details,
summary {
    list-style: none;
}

::-webkit-details-marker,
summary::marker {
    display: none;
}

// Lists
ul,
ol {
    padding-left: 1.2em;
}

li {
    position: relative;
    margin-bottom: 0.5em;

    &:last-child {
        margin-bottom: 0;
    }
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
%unstyled-list,
[role="list"] {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style-image: none;
        margin-bottom: 0;

        &::after {
            content: none;
        }
    }
}

button,
[type="submit"] {
    @include unbuttonify;
}

svg {
    // width: 100%;
    // height: auto;

    &:where(:not(.svg--stroke)) {
        fill: currentColor;
    }
}

:where(.svg--stroke) {
    path {
        stroke: currentColor;
    }
}

*:focus,
*:focus-visible {
    outline: 3px dotted var(--color--focus);
    outline-offset: 3px;

    // Remove focus styles for mouse users
    &:not(:focus-visible) {
        &:focus,
        &:active {
            outline-style: none;
            outline-width: 0;
        }
    }
}

.btn-donate {
    background: var(--color--green) !important;
    border: 0 !important;
    position: fixed;
    bottom: 0;
    right: 0;
    display: none !important;
    flex-direction: column-reverse;
    padding: 10px 8px 14px !important;
    gap: 4px;
    font-size: 10px;
    z-index: 999;
    color: var(--color--black) !important;
    width: 64px;
    font-family: "aktiv-grotesk-extended";
    line-height: 14px;
    transition: all 600ms ease !important;

    @include mq(m) {
        display: flex !important;
    }

    svg {
        --btn--icon-size: 40px !important;
    }

    &:hover {
        padding-right: 16px;
        width: 72px;
    }
}
