@use "../base" as *;

.hero-video {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    z-index: 2;
    bottom: 0;
    margin: 0;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    height: 100%;
    transform: translate(-50%, 0px);

    @include mq(m) {
        left: 0;
        height: 100%;
        transform: none;
        width: 100%;
    }

    iframe {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        aspect-ratio: 16/9;
    }
}

.control-buttons {
    position: absolute;
    left: 16px;
    top: -84px;
    bottom: auto;
    right: 0;

    @include mq(m) {
        bottom: 0;
        right: 90px;
        left: auto;
        top: auto;
    }

    button {
        svg {
            path {
                transition: all 500ms ease;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }

        &:hover {
            svg {
                path {
                    fill: rgba(205, 202, 201, 1);
                }
            }
        }
    }
}
