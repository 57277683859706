@use "sass-mq" as *;
@use "type-styles" as *;

%input {
    display: block;
    width: 100%;
    padding: var(--input--padding-block) var(--input--padding-inline);
    border: var(--input--border-width) solid var(--input--border-color);
    border-radius: var(--input--border-radius) !important;
    background: var(--input--background-color);
    box-shadow: none;
    color: var(--input--color) !important;
    font-family: inherit;
    line-height: inherit;
    height: 56px !important;
}

%label,
.gform-field-label {
    font-size: 18px !important;
    line-height: 30px !important;
    font-weight: var(--fw--bold) !important;
    margin-bottom: 8px !important;
}

%input--button-height {
    --input--padding-y: calc(
        var(--button--padding-y) +
            (var(--button--border-width) - var(--input--border-width))
    );
}

%select {
    @extend %input;
    display: flex !important;
    align-items: center !important;
    position: relative;
    min-width: 6em;
    max-width: 100%;

    &:not([multiple]) {
        padding-right: 2em;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.382823 0.817787C-0.0565166 1.25713 -0.0565165 1.96944 0.382823 2.40878L5.15579 7.18175C5.59513 7.62109 6.30744 7.62109 6.74678 7.18175C7.18612 6.74241 7.18612 6.0301 6.74678 5.59076L1.97381 0.817787C1.53447 0.378447 0.822163 0.378447 0.382823 0.817787Z' fill='black'/%3E%3Cpath d='M11.617 0.817819C11.1777 0.378479 10.4654 0.378479 10.026 0.817819L5.25307 5.59079C4.81373 6.03013 4.81373 6.74244 5.25307 7.18178C5.69241 7.62112 6.40472 7.62112 6.84406 7.18178L11.617 2.40881C12.0564 1.96947 12.0564 1.25716 11.617 0.817819Z' fill='black'/%3E%3C/svg%3E%0A");
        background-position: right var(--input--padding-block) top 50%;
        background-repeat: no-repeat;
        background-size: 0.6875em auto;
        cursor: pointer;

        &:focus,
        &:active {
            background-color: transparent;
        }
    }
}

.form-grid,
%form-grid {
    display: grid;
    grid-auto-flow: row;
    column-gap: var(--form--row-gap);
    row-gap: var(--form--row-gap);
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "left left left right right right";

    > * {
        grid-column: 1/-1;
    }
}

%form-item--left,
.form-item--left {
    @include mq(s) {
        grid-column: left;
    }
}

%form-item--right,
.form-item--right {
    @include mq(s) {
        grid-column: right;
    }
}

%form-field--compound,
.form-field--compound {
    display: flex;
    flex-flow: row wrap;
    gap: var(--space--s);
    align-items: flex-start;

    label {
        width: 100%;
    }
}

::placeholder {
    color: var(--input--placeholder-color);
    opacity: 1;
}

form {
    margin: 0;
}

fieldset {
    width: 100%;
    padding: 0;
    border: 0;
    margin-right: 0;
    margin-left: 0;

    + fieldset {
        margin-top: var(--form--row-gap);
    }
}

label {
    @extend %type-input-label;

    display: inline-block;
    cursor: pointer;
    margin-bottom: var(--form--label-spacing);
}

legend {
    font-weight: var(--fw--medium);
    margin-bottom: var(--form--row-gap);
}

[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
    @extend %input;
}

select {
    @extend %select;
}

textarea {
    resize: vertical;
}
