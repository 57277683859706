@use "../base" as *;

.calendar-container {
    flex-shrink: 0;
    padding: 0 var(--space--m);

    @include mq(m) {
        width: 380px;
        padding: 0;
    }

    .react-datepicker {
        width: 100%;
        background: transparent;
        border: 0;

        &__month-container {
            width: 100%;
        }

        &__header {
            background: transparent;
            border: 0;
            padding: 0;
        }

        &__current-month {
            color: #fff;
            margin-bottom: 4px;

            @extend %type-body-l;
            @extend %bold;
            font-family: var(--ff--secondary);
        }

        &__day-names,
        &__week {
            white-space: nowrap;
            margin-bottom: 6px;
            display: flex;
            justify-content: space-evenly;
        }

        &__day-name,
        &__day {
            color: #fff;
            font-family: "aktiv-grotesk";
            flex-shrink: 0;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__day {
            font-weight: 500;

            &--disabled {
                color: rgba(180, 178, 178, 1);
            }

            &--outside-month {
                color: rgba(127, 124, 124, 1);
            }

            &.event-happening {
                border: 1px solid var(--color--white);
                border-radius: 50%;
                color: var(--color--white);

                @extend %type-body-l;
                transition: all 500ms ease;

                &:hover {
                    background: rgba(244, 1, 151, 1);
                    color: var(--color--black);
                    border: 1px solid transparent;
                }
            }

            &--selected {
                background: rgba(244, 1, 151, 1);
                border: 0 !important;
                color: var(--color--black) !important;
            }

            &--today {
                background: transparent !important;
                color: #fff !important;
            }
        }

        &__month {
            margin: 0;
            text-align: center;
        }
    }
}
