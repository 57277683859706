@use "../base" as *;

.page-template-press-overview {
    .downloads {
        .inner-wrapper {
            max-width: 100%;
        }

        &__list {
            --grid--columns: 1;
            --grid--item--min-width: initial;

            @include mq(m) {
                --grid--columns: 3 !important;
            }
        }
    }

    .news-content {
        &__cards {
            --grid--columns: 1;
            --grid--row-gap: 16px;

            @include mq(m) {
                --grid--columns: 3 !important;
            }

            .card {
                &__heading {
                    @extend %type-h5;
                    @extend %bold;
                    text-wrap: pretty;
                }

                &__summary {
                    margin-bottom: 0;
                }

                &__actions {
                    opacity: 0;
                    height: 1px;
                }
            }
        }
    }
}
