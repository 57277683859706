@use "../base" as *;

.event-instances {
    @extend %margin-trim;
    margin-bottom: 80px !important;

    &__heading {
        @extend %type-h4;

        margin-bottom: var(--space--prose);
    }

    &__items {
        @include unstyled-list;

        display: grid;
        gap: var(--space--base);
    }

    .btn--alt {
        margin-top: var(--space--l);
        margin-left: var(--space--m);
        margin-right: var(--space--m);
        width: calc(100% - 32px);
        display: flex;
        justify-content: space-between;

        @include mq(m) {
            margin-top: var(--space--xl);
            margin-left: 0;
            margin-right: 0;
            width: auto;
        }
    }
}

.event-instance {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: var(--space--base);

    &__details {
        text-align: right;
        flex: 1;
    }

    p {
        margin: 0;
    }

    .booking-button {
        flex-shrink: 0;
        width: 150px;
    }
}
