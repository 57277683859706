@use "../base" as *;

.support-block {

    &__image {
        position: relative;
        aspect-ratio: 16/9;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: opacity var(--transition--base);
        }

        .active {
            img {
                opacity: 1;
            }
        }
    }

    .container {
        padding: 0;

        @include mq(m) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    .inner-wrapper {
        @include mq(m) {
            padding: var(--space--20);
            background: var(--color--green-light);
        }
    }

    .content {
        background: var(--color--green-light);
        padding: var(--space--20) var(--space--m) var(--space--layout--xs);

        @include mq(m) {
            margin-right: 85px;
            padding: var(--space--20) var(--space--layout--m) 0 var(--space--l);
        }

        p {
            margin-bottom: var(--space--l);
        }

        .amounts {
            display: flex;
            align-items: center;
            gap: var(--space--m);
            margin-bottom: var(--space--m);

            .amount {
                display: flex;
                align-items: center;

                input {
                    display: none;
                }

                label {
                    @extend %btn;
                    --fs--base: 20px;
                    --btn--padding-inline: var(--space--xl);
                    background-color: var(--color--green-light);
                }

                input:checked + label {
                    background-color: var(--color--black);
                    color: var(--color--white);
                }
            }
        }

        .amount-message {
            @extend %type-body-l;
            @extend %bold;
            margin-bottom: var(--space--m);
        }

        .custom {
            margin-bottom: var(--space--xl);

            input {
                text-align: center;
            }
        }

        .btn--alt {
            width: 100%;
            display: flex;

            @include mq(m) {
                width: auto;
            }
        }
    }
}

.support-dialog {
    .support-dialog-wrapper {
        width: var(--width--max);

        @include mq(l) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    .content {
        background: linear-gradient(180deg, #def783 15%, #def783 25%, #fff 55%);
        width: 100%;
        margin: 0;
        padding: 24px 16px;
        overflow: scroll;
        height: 100dvh;
        margin-inline: auto;

        @include mq(l) {
            padding: 64px 48px;
            // margin: 0 64px;
        }

        @include mq(xl) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 48px;
            height: auto;
            overflow: visible;
        }

        h4 {
            font-weight: 700;
            margin: 0 0 var(--space--l);
        }

        p {
            margin: 0 0 var(--space--xl);

            @extend %type-body-l;
        }

        .video-content {
            flex-shrink: 0;
        }

        iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
            margin: 0;
        }

        .btn-secondary-large {
            width: 100%;
            display: inline-block;
            background: var(--color--black);
            color: var(--color--white);
            margin-bottom: 36px;

            @include mq(l) {
                width: auto;
            }

            @include mq(xl) {
                margin-bottom: 0;
            }

            &:hover {
                background: var(--color--white);
                color: var(--color--black);
            }
        }

        .btn--cross {
            svg {
                path {
                    fill: var(--color--black);

                    @include mq(xl) {
                        fill: var(--color--white);
                    }
                }
            }

            @include mq(xl) {
                position: absolute;
                right: -10px;
                top: -44px !important;
            }
        }
    }
}
