@use "../base" as *;

// Block specific
.accordion {
    .section__heading {
        margin-bottom: 54px;

        @extend %type-h1;
    }
}

.accordion-item {
    $self: &;

    position: relative;
    border-top: 1px solid var(--color--black);

    &::before {
        content: " ";
        display: block;
        position: absolute;
        border-top: 1px solid;
        top: -1px;
        width: 3000px;
        left: -3000px;
    }

    // &:last-child {
    //     border-bottom: 1px solid var(--color--black);
    // }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--space--m);
        text-align: left;
        width: 100%;
        padding: var(--space--m) 0 var(--space--s);
        font-family: var(--font-family-medium);
        transition: background-color var(--transition--base);

        &:hover,
        &:focus-visible {
            // @include color-context("neutral-950", $colors);

            // background-color: var(--color--neutral-950-500);
        }

        &[aria-expanded="true"] {
            // background-color: var(--color--neutral-950);
            // @include color-context("neutral-950", $colors);

            #{$self}__icon {
                &::before {
                    rotate: 0deg;
                    mask-image: url("../images/minus.svg");
                    background-color: var(--color--black);
                }
            }
        }

        #{$self}__icon {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            display: flex;
            padding: 0;
            align-items: center;
            border: 0;
            background-color: transparent;

            &::before {
                rotate: 90deg;
                background-color: var(--color--black);
                mask-image: url("../images/plus.svg");
                transition: rotate var(--transition--base);
            }
        }
    }

    &__heading {
        margin: 0;
    }

    &__content-panel {
        width: 100%;
        overflow: hidden;
        background-color: var(--color--neutral-950-300);
        transition: height var(--transition--base);
    }

    &__content {
        padding: var(--space--s) 0 var(--space--l);

        p {
            margin: 0 0 var(--space--20);

            @extend %type-body-l;
        }

        @include mq(m) {
            display: flex;
            gap: var(--space--20);
        }

        &-image {
            width: 406px;
            flex-shrink: 0;
        }

        .text-content {
            img {
                margin-bottom: var(--space--l);
                aspect-ratio: 16 / 9;
                object-fit: cover;

                @include mq(m) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.accordion-item__content-panel {
    overflow: hidden;
    transition: height 0.3s ease;
}

.accordion-item__content-panel[aria-hidden="true"] {
    visibility: hidden;
    height: 0;
}

.accordion-item__content-panel[aria-hidden="false"] {
    visibility: visible;
}
