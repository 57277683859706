@use "../base" as *;

.section--media {
    // block Specific

    .section__header {
        margin-bottom: var(--space--xl);
        padding-right: 90px;
    }

    .section__heading {
        @extend %type-h1;
    }

    // Main Carousel
    .media-carousel {
        .media-item {
            position: relative;

            img {
                aspect-ratio: 16/9;
                object-fit: cover;
                transform: scale(1);
                transition: all 1000ms ease;
            }

            .btn-play {
                overflow: hidden;
            }

            .btn--icon {
                position: absolute;
                top: 0;
                left: 0;
                background: var(--color--black);
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mq(m) {
                    width: 64px;
                    height: 64px;
                }

                svg {
                    width: 11px;

                    @include mq(m) {
                        width: 18px;
                    }
                }
            }

            .btn-play:hover img {
                transform: scale(1.1) !important;
            }
        }

        .caption {
            background: var(--color--white);
            padding: var(--space--xs) 0;
            position: relative;
            text-align: left;

            @extend %type-body-s;

            @include mq(m) {
                padding: var(--space--xs) var(--space--m);
                margin-right: 105px;
                margin-top: -38px;
                margin-bottom: var(--space--l);
            }
        }

        &-nav {
            margin: 0 -4px;

            @include mq(m) {
                margin: 0 -10px;
            }

            img {
                aspect-ratio: 1/1;
                object-fit: cover;
            }

            // .slick-list {
            //     margin: 0 -8px;

            //     @include mq(m) {
            //         margin: 0 -20px;
            //     }
            // }

            .slick-track {
                display: flex;
                // width: 100% !important;
                .slick-slide {
                    padding: 0 4px;

                    @include mq(m) {
                        padding: 0 10px;
                    }
                }
            }

            .video-image-wrapper {
                position: relative;

                .btn--icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: var(--color--black);
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            button {
                position: relative;
                border: 2.5px solid var(--color--white);

                &:hover {
                    .overlay {
                        transition: 300ms ease;
                        opacity: 1;
                    }
                }
            }

            .overlay {
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: 0.5s ease;
                opacity: 0;
            }

            .slick-current {
                button {
                    border: 2.5px solid var(--color--green);
                }
            }
        }
    }
}

.video-dialog {
    .content {
        position: relative;

        button {
            background: transparent !important;
            width: 40px !important;
            height: 40px !important;
            right: -5px;
            top: -24px !important;

            svg {
                width: 28px !important;
            }
        }
    }
}
