@use "../base" as *;

.downloads {
    h2 {
        @extend %type-h1;

        margin: 0 0 var(--space--xl);
    }

    &__list {
        --grid--item--min-width: 260px;
        gap: var(--space--m);
        align-items: flex-start;

        @include mq(m) {
            gap: var(--space--20);
        }

        .wrapper {
            display: flex;
            flex-direction: column;
        }
    }

    &__item {
        @extend %hidden-link;

        padding: var(--space--m);
        border: 1px solid var(--color--black);
        transition: all 500ms ease;

        &__title {
            margin-bottom: var(--space--xs);
            font-weight: var(--fw--semibold);

            @include mq(m) {
                font-weight: var(--fw--bold);
            }
        }

        &__date {
            margin: 4px 0 12px;

            @extend %type-body-s;
        }

        &__content {
            display: flex;
            align-items: center;
            gap: var(--space--xs);
        }

        svg {
            path {
                transition: all 500ms ease;
            }
        }

        &:hover,
        &:focus {
            background-color: var(--color--black);
            color: var(--color--white);

            svg {
                path {
                    fill: var(--color--white);
                }
            }
        }
    }
}
