@use "../base" as *;

.section-navigation {
    background-color: var(--color--neutral-900);

    .container {
        padding-inline: 0;

        @include mq(m) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    .accordion-item {
        position: relative;
        border-top: 0;

        &::before {
            content: none;
        }

        &__header {
            padding: 18px var(--space--m);
            color: var(--color--white);

            @include mq(m) {
                padding: 18px 0;
            }
        }

        &__heading {
            font-size: 16px;
        }

        &__icon::before {
            background-color: var(--color--white);
        }

        &__header[aria-expanded="true"] .accordion-item__icon::before {
            background-color: var(--color--white);
        }

        &__content {
            padding: var(--space--20) var(--space--m) var(--space--l);
            list-style: none;

            &-panel {
                border-top: 1px solid var(--color--white);
            }

            li {
                margin: 0;
                padding: 0 0 18px;
                position: relative;

                &:last-child {
                    padding: 0;
                }

                a {
                    text-decoration: none !important;

                    &:hover {
                        color: rgba(180, 178, 178, 1);
                    }
                }

                &.active,
                &.current_page_item {
                    color: var(--color--white);
                    margin: 0;
                    position: relative;

                    @extend %bold;

                    a {
                        position: relative;

                        &::after {
                            content: "";
                            height: 1px;
                            width: auto;
                            background: #c2ea2e;
                            display: inline-block;
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            right: 0;
                        }
                    }
                }

                &.current_page_item {
                    a {
                        pointer-events: none;
                        color: var(--color--white) !important;
                    }
                }

                &.section-navigation__root {
                    a {
                        color: var(--color--white);
                        margin: 0;

                        @extend %bold;
                    }

                    &:hover {
                        a {
                            color: rgba(180, 178, 178, 1);
                        }
                    }
                }

                &.current {
                    pointer-events: none;

                    a {
                        color: var(--color--white) !important;
                        position: relative;

                        &::after {
                            content: "";
                            height: 1px;
                            width: auto;
                            background: #c2ea2e;
                            display: inline-block;
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    .page_item,
    .current_page_item {
        a {
            color: var(--color--white);
        }
    }
}
