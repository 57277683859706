@use "../base" as *;

.related-content {
    background-color: #fff;
    background-image: url("../../public/images/dots.svg");
    background-size: 4px;
    background-repeat: repeat;
    padding: var(--space--xl) 0;
    margin-bottom: 0;

    @include mq(m) {
        padding: 112px 0 var(--space--layout--s) 0;
    }

    .section__heading {
        @extend %type-h1;
    }

    &__cards {
        @include mq(m) {
            margin: 0 -10px;
        }

        .slick-list {
            display: flex;

            .slick-track {
                display: flex;
                // gap: var(--space--20);
                margin: 0;
                width: 100% !important;

                .slick-slide {
                    display: flex !important;
                    flex-shrink: 0;
                    padding: 0 10px;
                }
            }
        }

        .card__content {
            text-align: left;
        }

        .card__summary {
            display: none;

            @include mq(m) {
                display: block;
            }
        }

        .card.hidden-instance {
            display: none;
        }
    }

    .btn-related {
        width: 100%;
        margin-top: 48px;
        margin-bottom: 48px;
    }
}
