@use '../1-variables/' as *;

// For each width in $widths, create a class that sets the max-width of an element.
// e.g. .width--l { max-width: var(--width--responsive--l); }
@each $width in $widths {
    $class: '.width--#{nth($width, 1)}';
    $value: 'var(--width--#{nth($width, 1)})';

    #{$class} {
        max-width: #{$value};
    }
}

.width--full {
    max-width: 100%;
}

.width--full--padded {
    max-width: var(--width--full--padded);
}
