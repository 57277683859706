@use "../0-helpers" as *;
@use "sass-mq" as *;

%type-heading-xl,
.type-heading-xl {
    font-family: var(--ff--primary);
    font-weight: var(--fw--bold);
    font-size: #{rfs(40, 72)};
    line-height: 1.18;
}

%type-h1,
.type-h1 {
    font-family: var(--ff--primary);
    font-weight: var(--fw--semibold);
    font-size: #{rfs(32, 44)};
    line-height: 1.28;
    font-weight: var(--fw--semibold);

    @include mq("m") {
        line-height: 1.18;
    }
}

%type-h2,
.type-h2 {
    font-family: var(--ff--primary);
    font-weight: var(--fw--semibold);
    font-size: #{rfs(26, 40)};
    line-height: 1.28;
    margin-bottom: var(--space--s);
    font-weight: var(--fw--semibold);

    @include mq("m") {
        line-height: 1.18;
    }
}

%type-h3,
.type-h3 {
    font-family: var(--ff--primary);
    font-weight: var(--fw--semibold);
    font-size: #{rfs(24, 32)};
    line-height: 1.28;
    font-weight: var(--fw--semibold);

    @include mq("m") {
        line-height: 1.18;
    }
}

%type-h4,
.type-h4 {
    font-family: var(--ff--primary);
    font-weight: var(--fw--normal);
    font-size: #{rfs(22, 24)};
    line-height: 1.18;
}

%type-h5,
.type-h5 {
    font-family: var(--ff--primary);
    font-weight: var(--fw--normal);
    font-size: #{rfs(20, 20)};
    line-height: 1.66;

    &.no-marg {
        margin: 0;
    }
}

%type-h6,
.type-h6 {
    font-family: var(--ff--primary);
    font-weight: var(--fw--bold);
    font-size: #{rem(20)};
    line-height: 1.25;
}

%type-heading-xs,
.type-heading-xs {
    font-family: var(--ff--primary);
    font-weight: var(--fw--bold);
    font-size: #{rfs(14, 16)};
    letter-spacing: 0.7px;
    text-transform: uppercase;
    line-height: 1.14;

    @include mq("m") {
        line-height: 1;
    }
}

%type-card-header,
.type-card-header {
    font-family: var(--ff--primary);
    font-weight: var(--fw--bold);
    font-size: #{rfs(20, 24)};
    line-height: 1.4;
}

%type-card-date,
.type-card-date {
    font-family: var(--ff--secondary);
    font-weight: var(--fw--medium);
    font-size: #{rem(14)};
    line-height: 1.4;
}

%type-button,
.type-button {
    font-size: var(--fs--base);
    font-weight: var(--fw--normal);
    line-height: 1.5;
}

%type-body-xs,
.type-body-xs {
    --space--prose: 16px;

    font-family: var(--ff--secondary);
    font-size: #{rfs(12, 12)};
    font-weight: var(--fw--normal);
    line-height: 1.66;
}

%type-body-s,
.type-body-s {
    --space--prose: 20px;

    font-family: var(--ff--secondary);
    font-size: #{rfs(14, 14)};
    font-weight: var(--fw--normal);
    line-height: 1.66;
    margin-bottom: var(--space--xs);

    &.no-marg {
        margin: 0;
    }
}

%type-body,
.type-body {
    font-family: var(--ff--secondary);
    font-size: #{rfs(16, 16)};
    font-weight: var(--fw--normal);
    line-height: 1.66;
}

%type-body-l,
.type-body-l {
    font-size: 18px;
    font-weight: var(--fw--normal);
    line-height: 1.66;
}

%type-body-xl,
.type-body-xl {
    font-size: #{rfs(22, 24)};
    font-weight: var(--fw--normal);
    line-height: 1.4;

    @include mq(m) {
        line-height: 1.5;
    }
}

%type-input-label,
.type-input-label {
    font-family: var(--ff--secondary);
    font-size: var(--fs--body);
    font-weight: var(--fw--bold);
    line-height: 1.3;
}

%bold,
.bold {
    font-weight: var(--fw--bold);
    margin-bottom: 10px;
}
