@use "../base" as *;

.page-header--whatson,
.page-header--discover {
    padding-top: 30px;
    color: var(--color--white);

    .container {
        padding: 0;

        @include mq(m) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    @include mq(m) {
        padding-top: 40px;
    }

    .post-header__heading {
        margin: 0 0 var(--space--l);
        padding: 0 var(--space--m);

        @extend %type-heading-xl;

        @include mq(m) {
            margin: 0 0 40px;
            padding: 0;
        }
    }
}

.top-filters {
    padding-bottom: var(--space--m);
    gap: var(--space--xl);
    transition: height var(--transition--base);

    @include mq(l) {
        display: flex;
        align-items: flex-start;
        // margin-bottom: 16px;
        padding-bottom: var(--space--m);
    }
}

#more-filters {
    margin-bottom: 0;
    border-bottom: 0;
}

.more_filters--btn {
    display: flex;
    justify-content: flex-start;
    padding: 0 var(--space--m) 16px;
    gap: 8px;
    font-size: 16px;
    font-family: var(--ff--primary);
    font-weight: 700;
    height: 60px;
    align-items: center;
    cursor: pointer;

    @include mq(m) {
        padding: 16px 0;
        margin: 0 0 0 auto;
    }

    &[aria-expanded="true"] {
        border-top: 1px solid var(--color--white);
        justify-content: flex-start;
        width: 100%;

        @include mq(m) {
            justify-content: flex-end;
        }
    }
}

.filter-category {
    margin: 0 0 var(--space--l);
    width: auto;
    display: flex;
    gap: 8px;
    padding: 0 var(--space--m);

    @include mq(l) {
        margin: 0;
        padding: 0;
    }

    &__title {
        margin: 0 0 var(--space--20);

        @extend %bold;
    }

    &__legend {
        margin: 0;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        max-width: 600px;

        &-item {
            margin: 0;

            &-input {
                display: none;
            }

            &-label {
                @extend %btn;
                color: var(--color--white);
                border-color: var(--color--white);

                @extend %type-body-l;
                font-weight: var(--fw--bold) !important;

                font-family: "aktiv-grotesk";
                gap: 8px;
                text-align: left;

                &.custom {
                    font-size: 14px;
                    padding: 4px 8px;
                }

                @include mq(m) {
                    height: 38px;
                    padding: var(--btn--padding-block)
                        var(--btn--padding-inline);

                    &.custom {
                       font-size: 18px;
                       height: 38px;
                       padding: var(--btn--padding-block)
                        var(--btn--padding-inline);
                    }
                }

                &:hover {
                    background-color: var(--color--white);
                    color: var(--color--black);
                    border-color: var(--color--white);
                }

                .close-icon {
                    display: none;

                    svg {
                        width: 18px;
                        height: 14px;
                    }
                }
            }

            &-input:checked + label {
                background: linear-gradient(90deg, #f40197 0%, #f40197 100%);
                border: var(--color--neutral-900);
                color: var(--color--black);

                .close-icon {
                    display: inline-flex;
                }

                &:hover {
                    background: var(--color--white);
                }
            }
        }
    }

    &__btn {
        @extend %btn;
        color: var(--color--white);
        border-color: var(--color--white);
        width: auto;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 38px;

        &.custom {
            height: 33.23px;
        }

        @include mq(m) {
            &.custom {
                height: 38px;
            }
        }

        svg {
            width: 18px;
            height: 20px;
        }

        &[aria-expanded="true"] {
            background: rgba(244, 1, 151, 1);
            color: var(--color--black);

            svg {
                path {
                    fill: var(--color--black);
                }
            }
        }

        &:hover {
            background-color: var(--color--white);
            color: var(--color--black);

            svg {
                path {
                    fill: var(--color--black);
                }
            }
        }
    }
}

// Selected Filters
.filters-selected {
    padding: 32px 0 0;

    &[aria-hidden="true"] {
        padding: 0;
    }

    &__content {
        @include mq(l) {
            display: flex;
            align-items: flex-start;
            gap: var(--space--s);
        }

        p {
            @extend %type-body;
            @extend %bold;

            margin: 0 0 8px;
            flex-shrink: 0;

            @include mq(l) {
                margin: 0;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        gap: var(--space--xs);
        list-style: none;

        &-item {
            margin: 0;

            button {
                background: linear-gradient(90deg, #f40197 0%, #f40197 100%);
                padding: 3px var(--space--20);
                display: flex;
                align-items: center;
                gap: 8px;
                border: 0;
                text-align: left;

                @extend %type-body-l;
                font-weight: var(--fw--bold) !important;
                font-family: "aktiv-grotesk";

                @include mq(m) {
                    height: 38px;
                    padding: var(--btn--padding-block)
                        var(--btn--padding-inline);
                }

                .close-icon {
                    padding-top: 4px;
                }

                &:hover {
                    background: var(--color--black);

                    svg {
                        path {
                            fill: var(--color--white);
                        }
                    }
                }
            }
        }
    }
}

// Page Specific
.page-template-whats-on,
.page-template-discover {
    .hidden-card {
        display: none;
    }

    .article__content {
        margin: 0;
    }

    .related-content {
        background: linear-gradient(180deg, #edff9c 0%, #e1ff64 25%, #fff 100%);
        padding: 64px 0 0;

        .section__heading {
            padding-bottom: 12px;
            border-bottom: 1px solid;
            margin-bottom: 24px;
        }
    }

    .related-content__cards,
    .feed_cards {
        margin: 0;
        --grid--columns: 1;

        @include mq(m) {
            --grid--columns: 3;
        }
    }
}

.react-cards-feed {
    .section__heading {
        @extend %type-h1;
        margin-top: 64px;
        padding-bottom: 12px;
        border-bottom: 1px solid;
        margin-bottom: 24px;
    }

    .btn--alt {
        width: 100%;
        justify-content: space-between;
        margin: var(--space--l) 0;

        @include mq(m) {
            width: 400px;
            margin: 42px auto;
        }
    }
}

#more-filters {
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    transition:
        max-height 300ms ease,
        padding-bottom 300ms ease;

    &[aria-hidden="false"] {
        max-height: 1000px;
        padding-bottom: var(--space--m);
    }
}
