@use "../base" as *;

.form-block {
    margin: var(--space--layout--xs) 0 !important;

    @include mq(m) {
        padding-right: 96px;
    }

    .gform_title {
        margin: 0;

        @extend %type-h4;
        @extend %bold;
    }

    .gform_fields {
        row-gap: var(--space--xl) !important;
    }

    .gfield_required_text {
        font-size: 18px;
        color: #000;
        font-weight: 400;
    }

    .gfield_description {
        color: var(--Neutral-600, rgba(89, 89, 89, 1));
        margin: var(--space--m) 0 !important;
        font-size: 16px !important;
    }

    .gchoice {
        display: flex;
        align-items: center;

        label {
            font-size: 16px !important;
            font-weight: 400 !important;
        }
    }

    .gfield-choice-input {
        position: relative;

        &::before {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            width: 12px !important;
            height: 12px !important;
            background: var(--color--black) !important;
        }

        &[type="checkbox"] {
            &::before {
                background: transparent !important;
                color: var(--color--black) !important;
            }
        }
    }

    .gform_button {
        padding: 10px 34px !important;
        min-width: var(--btn--size, #{rem(44)}) !important;
        min-height: var(--btn--size, #{rem(44)}) !important;
        z-index: 1 !important;
        border: 1px solid var(--color--black) !important;
        display: flex !important;
        gap: 10px !important;
        align-items: center !important;
        font-weight: var(--fw--bold) !important;
        font-family: var(--ff--primary) !important;
        background: transparent !important;
        color: var(--color--black) !important;
        border-radius: 0 !important;
        margin-top: 24px !important;

        svg {
            width: var(--btn--icon-size, 24px) !important;
            height: var(--btn--icon-size, 24px) !important;
        }

        &:hover {
            background: var(--color--black) !important;
            color: var(--color--white) !important;
        }

        &::after {
            width: 24px;
            height: 24px;
            background-color: currentColor !important;
            content: "" !important;
            mask-position: center !important;
            mask-repeat: no-repeat !important;
            mask-size: contain !important;
            mask-image: url("../images/arrow.svg") !important;
            display: block !important;
        }
    }
}
