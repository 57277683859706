@keyframes slide-down {
    from { transform: translateY(-100%); }
    to { transform: translateY(0); }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
