@use "../base" as *;

.global-banner {
    position: relative;
    background: #000;
    z-index: 9;
    border-bottom: 1px solid #fff;
    padding: 20px 0;

    p {
        margin: 0;
        color: var(--color--white);
    }

    a {
        text-decoration: underline;

        @extend %bold;
    }

    .content {
        display: flex;
        gap: 22px;
        align-items: center;
        justify-content: center;

        button {
            flex-shrink: 0;
        }
    }

    + .site-header {
        top: 100px;
    }
}
