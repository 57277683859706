@use "sass-mq" as *;
@use "../base" as *;

.tn-account-create-page,
.tn-account-create-brief-page,
.tn-payment-page {
    form {
        @include mq(m) {
            width: var(--width--m);
        }

        .tn-login-component,
        .tn-patron-component,
        .tn-address-component,
        .tn-component-contact-permissions {
            background: var(--color--neutral-100);
            padding: var(--space--layout--xs) var(--space--20);
            width: 100%;
            margin-bottom: var(--space--20);

            @include mq(m) {
                padding: var(--space--layout--xs);
                margin-bottom: var(--space--xl);
            }

            &__settings__item {
                padding: 0;
                margin: 0px;
                display: block;
                width: 100%;
                border: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &__header {
                    margin-bottom: var(--space--20);

                    @extend %type-body-l;

                    .ng-binding {
                        display: inline-block;
                    }
                }
            }
        }

        .tn-component-contact-permissions__header {
            .medium {
                display: none;
            }
        }
    }

    .tn-basket-button-container {
        flex-direction: column;
        align-items: flex-end !important;

        .tn-page-bottom {
            flex: 1 0 100%;
        }
    }
}

// Create / signup
.tn-account-create-page,
.tn-account-create-brief-page {
    .tn-component-contact-permissions__settings__item {
        .tn-component__fieldset-radio {
            .tn-component__radio-group {
                display: flex !important;
            }
        }
    }
}
