@use "sass-mq" as *;
@use "../base" as *;

.tnew-body {
    label {
        margin-bottom: var(--space--xs);

        input {
            display: none;
        }

        input:checked + .btn {
            background: var(--color--neutral-700);
            color: var(--color--white);
        }
    }
}
