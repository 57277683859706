@use "sass-mq" as *;
@use "../base" as *;

.tn-events-listing-page {
    .tn-events-keyword-filter,
    .tn-event-listing-view__controls-container,
    .tn-event-listing-view__results-container {
        border: 0;
        padding: var(--space--layout--xs) var(--space--20);
        background: var(--color--neutral-100);
        max-width: var(--width--m);
        margin-bottom: var(--space--20);

        @include mq(m) {
            padding: var(--space--layout--xs);
            margin-bottom: var(--space--xl);
        }
    }

    .tn-event-listing__primary-views-container {
        .tn-event-listing-mode-tab-nav__list {
            display: none !important;
        }

        .tn-events-list-view__datepicker-divider {
            align-items: center;

            .tn-events-list-view__datepicker-divider-label {
                @extend %type-body-l;
                font-style: normal;
                color: var(--color--black);
                font-weight: var(--fw--bold);
            }
        }
    }

    .tn-events-list-view {
        &__date-range {
            flex-wrap: wrap;
            gap: var(--space--l);
        }

        &__btn-reset-dates-container {
            flex: 1 0 100%;
            padding: 0;
        }

        &__datepicker-container,
        &__datepicker-divider {
            width: auto;
        }
    }

    // Events listing
    .tn-event-listing-view__results-container {
        ul {
            --grid--row-gap: var(--space--m);

            margin: 0;

            @include mq(m) {
                --grid--row-gap: var(--space--l);
            }
        }

        .tn-prod-list-item {
            width: 100%;
            padding: 0;
            background: transparent;
            display: block;
            border: 0;

            @include mq(m) {
                margin-top: var(--space--m);
            }

            &__property--heading {
                a {
                    @extend %type-h5;
                    font-weight: var(--fw--bold);
                    margin-bottom: var(--space--xs);
                    display: block;
                    text-decoration: none;

                    @include mq(m) {
                        margin-bottom: var(--space--l);
                    }
                }
            }

            &__property--perf-list {
                li {
                    margin-top: 0;
                    width: 100%;
                }
            }

            &__perf-anchor {
                background: var(--color--white);
                border-color: var(--color--black) !important;
                padding: var(--space--s);
                text-decoration: none;
                gap: var(--space--xs);

                @include mq(m) {
                    gap: var(--space--l);
                }

                &:has(.tn-prod-list-item__perf-status) {
                    pointer-events: none;
                }

                .glyphicon {
                    display: none;
                }
            }

            &__property--img-container {
                display: none;
            }
        }
    }

    .tn-prod-list-item {
        &__perf-property {
            @extend %type-body;

            color: var(--color--black);
            font-weight: var(--fw--normal);
            background: transparent;
            padding: 0;
            display: block;
            width: 100%;

            @include mq(m) {
                width: 30%;
            }

            &--title {
                margin-bottom: var(--space--xs);
            }

            &--datetime {
                font-weight: var(--fw--bold);
                opacity: 1;
                text-align: left;

                @include mq(m) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .tn-prod-list-item__perf-action,
        .tn-prod-list-item__perf-status {
            @extend %btn;
            @extend %btn--secondary-large;
        }

        .tn-prod-list-item__perf-status {
            pointer-events: none;
            border-color: var(--color--neutral-100);
            color: var(--color--neutral-500);
            background-color: transparent;
            font-style: normal;
        }
    }
}
