@use "../base" as *;

.job-overview-section {
    margin-block-end: var(--section--margin-block) !important;

    .section__heading {
        margin-bottom: var(--space--m);
        border-bottom: 1px solid;
        padding-bottom: 8px;
        position: relative;
        display: flex;
        flex-direction: column;

        @include mq(m) {
            padding-bottom: 28px;
            display: block;
        }

        &::before {
            @include mq(m) {
                content: " ";
                display: block;
                position: absolute;
                border-top: 1px solid;
                bottom: -1px;
                width: 3000px;
                left: -3000px;
            }
        }

        &-roles {
            @extend %type-body;
            font-weight: var(--fw--bold);
            color: var(--color--neutral-800);

            @include mq(m) {
                font-weight: normal;
            }
        }
    }

    &__jobs {
        --grid--columns: 1;
        --grid--row-gap: 8px;

        @include mq(m) {
            --grid--columns: 2;
        }

        &-item {
            padding: var(--space--s) var(--space--s) var(--space--m);

            @include mq(m) {
                padding: var(--space--m);
            }

            .post-header__tags-item {
                background-color: var(--color--black);
                color: var(--color--white);
            }

            h4 {
                @extend %bold;
                margin: 0 0 8px;
            }

            p {
                margin: 0 0 var(--space--m);

                @extend %type-body-s;
                @extend %bold;
            }
        }
    }

    .no-results {
        margin: 0;

        @extend %type-body-l;
    }
}

// Page specific
.page-template-jobs-overview {
    .article__content {
        margin-bottom: 0;
    }
}
