@use "../0-helpers/" as *;
@use "../1-variables" as *;

%hidden-link {
    text-decoration: none;
    color: inherit;

    @include interact {
        color: inherit;
        text-decoration: none;
    }
}

:where(:any-link),
%link {
    color: var(--link--color);
    text-decoration: var(--link--text-decoration);

    @include interact {
        color: var(--link--color--hover);
        text-decoration: var(--link--text-decoration--hover);
    }
}

.link--secondary,
%link--secondary {
    color: var(--link--color);
    text-decoration: var(--link--text-decoration);
    font-weight: var(--fw--bold);

    @include interact {
        color: var(--link--color--hover);
        text-decoration: var(--link--text-decoration--hover);
    }
}
