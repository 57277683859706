/* More intuitive box sizing model */
*,
*::before,
*::after {
    box-sizing: border-box;

    // Ensure elements never break out of their containers. Especially in grid contexts.
    min-width: 0;
}

/* Remove default margins */
:where(
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    figure,
    blockquote,
    dl,
    dd
) {
    margin: 0;
}

/* Prevent font size inflation */
html {
    text-size-adjust: none;

    &:focus-within {
        scroll-behavior: smooth;
    }
}

/* Allow percentage-based heights */
html,
body {
    height: 100%;
}

/* Set core body defaults */
body {
    min-height: 100%;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

/* Remove built-in form typography styles */
input,
button,
textarea,
select {
    font: inherit;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
    min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
    scroll-margin-block: 5ex;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

small {
    font-size: 80%;
}
