@use "sass-mq" as *;
@use "../base" as *;

.mailer {
    padding: 32px 0;
    margin-top: 32px;

    @include mq(m) {
        padding: 56px 0;
        margin-top: 64px;
    }

    .container {
        position: relative;
    }

    h2 {
        font-size: calc(32 / 16 * 1rem + (44 - 32) * var(--fluid--bp));
        margin-bottom: 14px;
        color: var(--color--black);

        @include mq(m) {
            font-size: calc(26 / 16 * 1rem + (40 - 26) * var(--fluid--bp));
        }
    }

    .btn--alt {
        width: auto;
        display: inline-flex;
        text-decoration: none;
        height: 40px;
        font-size: 16px;
        padding: 0 24px;
        color: var(--color--black);
        &:hover {
            color: var(--color--white);
        }

        @include mq(m) {
            height: 53px;
            padding: 10px 34px;
            font-size: 20px;
        }
    }

    .mailing-device-desktop {
        width: auto;
        position: absolute;
        bottom: -56px;
        right: 0;
        max-width: 600px;
        display: none;

        @include mq(m) {
            display: block;
        }
    }

    .mailing-device-mobile {
        position: absolute;
        bottom: -32px;
        width: 220px;
        right: 0;

        @include mq(m) {
            display: none;
        }
    }

    h5 {
        margin-bottom: 14px;
        margin-right: 130px;
        text-wrap: wrap;

        @include mq(m) {
            margin-bottom: var(--space--l);
        }
    }
}
