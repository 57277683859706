@use "sass-mq" as *;
@use "../base" as *;

.tnew-body {
    h2,
    h3,
    .tn-event-detail__title {
        @extend %type-h3;

        margin: 0 0 var(--space--l);
        color: var(--color--neutral-800);
        opacity: 1;

        &.tn-create-heading, 
        &.tn-create-brief-heading {
            color: var(--color--white)
        }
    }

    h4,
    .tn-event-detail__display-time {
        @extend %type-h4;
    }

    .tn-ticket-selector__pricetype-message {
        @extend %type-body;
        margin-top: var(--space--xs);
        font-style: normal;
        color: var(--color--neutral-500);
    }
}
