@use '../base' as *;

.event-details {
    @extend %grid;
    @include grid-columns(1, 3, 4);
}

.event-detail {
    h3 {
        @extend %type-h4;

        margin-bottom: 0;
    }
}
