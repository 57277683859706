@use "sass-mq" as *;
@use "../base" as *;

.slick-slider {
    text-align: right;
    position: relative;

    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }

    .slick-arrow {
        position: absolute;
        bottom: calc(100% + 44px);
        right: 0;
        width: 12px;

        @include mq(m) {
            width: 40px;
        }

        &[data-control-prev] {
            right: 34px;

            @include mq(m) {
                right: 44px;
            }

            svg {
                rotate: 180deg;
            }
        }

        &.slick-disabled {
            pointer-events: none;

            svg {
                path {
                    fill: rgba(209, 209, 209, 1);
                }
            }
        }

        &:hover {
            svg {
                path {
                    fill: rgba(127, 124, 124, 1);
                }
            }
        }
    }
}
