@use "sass-mq" as *;
@use "../base" as *;

// Page Specific
.tn-cart-details-page,
.tn-payment-page {
    .tn-header-component {
        .tn-heading-sub-text {
            margin-bottom: var(--space--20);

            @include mq(m) {
                margin-bottom: var(--space--layout--xs) !important;
            }
        }
    }

    // Cart Totals
    .tn-cart-totals {
        margin: var(--space--20) 0 !important;

        @include mq(m) {
            margin: var(--space--layout--xs) 0 !important;
        }

        &__line-item {
            margin: var(--space--xs) 0;

            @include mq(m) {
                margin: var(--space--m) 0;
            }

            justify-content: space-between;

            &--subtotal {
                .tn-cart-totals__label,
                .tn-cart-totals__value {
                    @extend %type-h5;
                    margin: 0;
                    width: auto;
                    color: var(--color--neutral-800);
                }

                .tn-cart-totals__value {
                    @extend %bold;
                }
            }

            &--total {
                margin: var(--space--s) 0;

                @include mq(m) {
                    margin: var(--space--xxl) 0;
                }

                .tn-cart-totals__label,
                .tn-cart-totals__value {
                    @extend %type-h3;
                    margin: 0;
                    width: auto;
                    color: var(--color--neutral-800);
                }

                .tn-cart-totals__value {
                    @extend %bold;
                }
            }
        }
    }
}

// Timer
.tn-timer {
    padding: 14px var(--space--m);
    background: var(--color--orange-light);
    margin-bottom: var(--space--20);
    display: inline-block;

    @include mq(m) {
        margin-bottom: var(--space--layout--xs);
    }

    &-description {
        @extend %type-h4;
    }

    .tn-count-down-timer {
        @extend %type-h4;
        @extend %bold;
    }
}

// Cart Items
.tn-cart__section-items {
    .tn-cart-item {
        padding: 0;
        border-top: 1px solid var(--color--neutral-500);
        border-bottom: 1px solid var(--color--neutral-500);

        &:last-child {
            border-bottom: 0;
        }

        &-summary {
            display: block;
            margin: var(--space--20) 0;
            position: relative;

            @include mq(m) {
                margin: var(--space--layout--xs) 0 var(--space--l);
            }

            &__property--name {
                @extend %type-h3;
                padding: 0;
                margin-bottom: var(--space--m);
                width: 75%;

                .tn-cart-line-item-name {
                    @extend %type-h3;
                }
            }

            &__property--price-total {
                @extend %type-h3;
                @extend %bold;

                @include mq(m) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 0;
                }
            }

            &__property--date-time {
                @extend %type-h5;
                @extend %bold;
                margin-bottom: var(--space--xs);
                display: block;
            }

            &__property--location {
                @extend %type-h5;
            }
        }

        &-details__list {
            display: block;
            padding: 0;
            background-color: transparent;
            margin-bottom: var(--space--20);

            @include mq(m) {
                margin-bottom: var(--space--layout--xs);
            }

            .tn-cart-item-detail__list {
                border: 0;
                padding: var(--space--m);
                margin: 0 0 var(--space--xs);
                background-color: var(--color--neutral-100);
                flex-flow: row;
                display: block;

                @include mq(m) {
                    display: flex;
                }

                &-item {
                    flex: auto;
                    color: var(--color--neutral-800);
                    margin: 0;
                    text-align: left;

                    &--description {
                        @extend %bold;
                    }
                }
            }
        }
    }
}
