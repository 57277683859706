%table,
.table {
    border-collapse: collapse;
    width: 100%;
    line-height: inherit;

    th,
    td {
        padding: var(--table--row--padding-block) var(--table--row--padding-inline);
    }

    th {
        height: var(--table--header--height);
        background-color: var(--table--header--background-color);
        border-bottom: var(--table--header--border);
        font-weight: var(--fw--bold);
        text-align: left;
    }

    td {
        height: var(--table--row--height);

        &:first-child {
            text-align: left;
        }
    }

    tr {
        &:nth-child(even) {
            background-color: var(--table--row--background-color--even);
        }

        &:nth-child(odd) {
            background-color: var(--table--row--background-color--odd);
        }
    }
}

%responsive-table,
.responsive-table {
    overflow-x: scroll;
    overflow-y: hidden;

    > table {
        margin-block: 0;
    }
}
