@use "sass-mq" as *;
@use "../base" as *;

// Page Specific
.tn-account-login-page {
    .tn-header-component {
        display: none;
    }

    .row {
        @include mq(m) {
            display: flex;
            justify-content: space-between;
            gap: var(--space--20);
        }

        &::after,
        &::before {
            content: none;
        }
    }

    // Login Section
    .col-xs-12.col-sm-6 {
        background: var(--color--neutral-100);
        padding: var(--space--layout--xs) var(--space--20);
        width: 100%;
        margin-bottom: var(--space--20);

        @include mq(m) {
            margin-bottom: var(--space--layout--xs);
            padding: var(--space--layout--xs);
        }

        &:nth-of-type(2) {
            background-color: var(--color--black);
            color: var(--color--white);

            .tn-create-sub-heading {
                @extend %type-body-l;
                @extend %bold;
                margin-bottom: var(--space--20);
            }

            .tn-create-brief-heading {
                @extend %type-h4;
                @extend %bold;
                margin-bottom: var(--space--20);
            }
        }
    }

    // Page footer
    .tn-page-bottom {
        p {
            @extend %type-body-s;
        }
    }
}
