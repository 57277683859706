.text-expand {
    &--active {
        max-height: unset;
        height: var(--text-expand--constrained-height, auto);
        overflow: hidden;
    }

    &__toggle {
        display: block;
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: 600;
        text-decoration: underline;
    }
}
