@use "../base" as *;

.overview-child-pages {
    &__cards {
        --grid--columns: 1;
        --grid--column-gap: 20px;
        --grid--row-gap: var(--space--xl);

        @include mq(m) {
            --grid--columns: 3;
        }

        .card__content {
            background-color: var(--color--black);
            color: var(--color--white);
        }

        .card__fill-link {
            font-weight: var(--fw--bold);

            &:hover {
                color: rgba(205, 202, 201, 1);
            }
        }
    }
}
