@use "sass-mq" as *;
@use "../base" as *;

.tnew-body {
    // Sub Nav
    .tn-subnav-component,
    i.fa {
        display: none;
    }

    // Header Title
    .tn-heading-container {
        background-color: var(--color--neutral-900);
        padding-bottom: var(--space--layout--xs);

        .tn-heading {
            @extend %type-heading-xl;

            margin: 0;
            padding: var(--space--layout--xs) 0 var(--space--l);
            color: var(--color--white);
            max-width: var(--width--m);

            &.sr-only {
                position: relative;
                white-space: nowrap;
                width: 100%;
                height: auto;
            }
        }
    }

    .tn-header-component {
        .tn-heading-sub-text {
            margin-bottom: 28px;

            @include mq(m) {
                margin-bottom: var(--space--xl);
            }
        }
    }

    // Main content container
    .tn-events-listing-page,
    .tn-cart-details-page,
    .tn-account-login-page,
    .tn-events-detail-page,
    .tn-account-create-page,
    .tn-account-create-brief-page,
    .tn-payment-page,
    .tn-account-update-page,
    .tn-account-interests-page,
    .tn-account-memberships-page,
    .tn-upcoming-events-page,
    .tn-contribution-individual-page,
    .tn-error-page,
    .tn-shipping-page,
    .tn-pre-cart-page {
        @extend %container;

        margin: var(--space--layout--xs) auto;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }
}

.subhead {
    background-color: var(--color--neutral-900);

    ul {
        margin: 0;
        gap: 0;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        @include mq(m) {
            gap: 0;
        }

        li {
            color: var(--color--white);
            font-weight: 400 !important;
            padding: 15px 0 19px;

            @extend %type-body-l;
            font-family: var(--ff--secondary);

            @include mq(m) {
                padding: 13px var(--space--20) 17px;
                font-size: 20px;
                font-family: var(--ff--primary);
            }

            &::after {
                left: 0 !important;
                right: 0 !important;

                @include mq(m) {
                    left: var(--space--20) !important;
                    right: var(--space--20) !important;
                }
            }

            a {
                text-decoration: none !important;

                &:hover {
                    color: rgba(180, 178, 178, 1);
                }
            }

            &.active,
            &.current_page_item {
                color: var(--color--white);
                margin: 0;

                @extend %bold;

                &::after {
                    content: "";
                    height: 1px;
                    width: auto;
                    background: #c2ea2e;
                    display: inline-block;
                    position: absolute;
                    bottom: 13px;
                }
            }

            &.current_page_item {
                a {
                    pointer-events: none;
                    color: var(--color--white) !important;
                }
            }

            &.section-navigation__root {
                a {
                    color: var(--color--white);
                    margin: 0;

                    @extend %bold;
                }

                &:hover {
                    a {
                        color: rgba(180, 178, 178, 1);
                    }
                }
            }

            &.current {
                pointer-events: none;

                a {
                    color: var(--color--white) !important;
                }

                &::after {
                    content: "";
                    height: 1px;
                    width: auto;
                    background: #c2ea2e;
                    display: inline-block;
                    position: absolute;
                    bottom: 13px;
                }
            }

        }
    }
}

.tn-empty-cart-message {
    padding: var(--space--l);
    background: var(--color--neutral-100);

    @include mq(m) {
        width: var(--width--m);
    }

    p {
        margin: 0 0 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@include mq(m) {
    .col-sm-6 {
        width: 100%;
    }
}

// Shipping
.tn-shipping-page {
    .col-xs-12,
    .col-sm-6 {
        @include mq(m) {
            width: var(--width--m);
            padding: 0;
        }
    }

    button {
        margin: 0 0 0 auto;
    }

    .tn-radio-group {
        margin-bottom: var(--space--l);
    }

    .tn-shipping-method {
        label {
            padding: 0;
            display: flex;
            align-items: center;
            gap: 8px;

            input {
                position: relative;
                display: block;
            }
        }
    }

    .tn-heading-sub-text {
        @extend %type-body-l;
    }
}

// Pre Cart
.tn-precart-response-message {
    @include mq(m) {
        width: var(--width--m);
    }
}

// modals

.modal {
    &[aria-labelledby="tn-modal-title"] {
        .modal-dialog {
            z-index: 100;
            display: flex;
            position: fixed;
            top: var(--wp-admin--admin-bar--height, 0);
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: scroll;
            padding-bottom: 1px;
            background-color: rgba(25, 24, 24, 0.8);
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0;

            .modal-content {
                box-shadow: none;
                border: 0;
                border-radius: 0;
            }

            .modal-header {
                padding: 48px 16px 0;
                border-bottom: 0;

                .close {
                    margin: 0;
                    border: 0;
                    padding: 0;
                    opacity: 1;
                }

                .modal-title {
                    border-bottom: 1px solid;
                    padding-bottom: 32px;
                    width: 100%;
                }
            }

            .modal-body {
                position: relative;
                padding: 20px 16px;

                #tn-delete-dialog-text {
                    font-size: 16px;
                    color: #000;
                    margin-bottom: 28px;
                }

                .checkbox {
                    margin-top: 0;
                    margin-bottom: 48px;

                    label {
                        padding-left: 0;
                        display: flex;
                        gap: 10px;
                        align-content: center;

                        input {
                            position: relative;
                            margin-left: 0;
                            flex-shrink: 0;
                        }
                    }
                }
            }

            .modal-footer {
                padding: 0 16px 56px;
                text-align: right;
                border-top: 0;
                flex-direction: column;

                button {
                    width: 100%;

                    &.modal-btn-cancel {
                        margin-bottom: 8px;
                    }

                    &.modal-btn-confirm {
                        background-color: var(--color--black);
                        color: var(--color--white);

                        &:hover {
                            background-color: transparent;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.tn-syos-btn-toggle-full-screen {
    border: 1px solid !important;
    background: transparent !important;
    .fa-expand, .fa-compress {
        display: block !important;
    }
}

.tn-syos-btn-view-screens {
    position: relative !important;
    right: 0 !important;
    top: 0 !important;
    @include mq(m) {
        position: absolute !important;
        right: var(--space--l) !important;
        top: var(--space--l) !important;
    }
}

.tn-syos-legend__btn-show-legend-mobile {
    left: 24px !important;
    bottom: -25px !important;
    background-color: transparent !important;
    border: 1px solid #000 !important;
    color: #000 !important;
    font-weight: 500 !important;
}

.g-recaptcha {
    div {
        width: auto !important;
        height: auto !important;
    }
}

// Promo Code
form#tn-apply-promo {
    display: flex;
    margin-bottom: 32px;
    input#tn-subnav-promo-code {
        width: 100%;
        text-align: center;

        @include mq(m) {
            width: 180px;
        }
    }
    button#tn-subnav-promo-button {
        font-family: var(--ff--primary);
        padding: 0 24px;
        border: 1px solid;
        margin-left: -1px;
        font-weight: 700;
        flex-shrink: 0;
        transition: all 500ms ease;
        &:hover {
            background-color: var(--color--neutral-700);
            border-color: var(--color--neutral-700);
            color: var(--color--white);
        }
    }
}

.tn-promo-applied {
    margin-bottom: 32px;
    .tn-text {
        border: 1px solid;
        height: 60px;
        display: inline-flex;
        padding: 0 24px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
    }
    .tn-link {
        display: none;
    }
}

.tn-ticket-selector {
    &__zone-selector-container {
        display: block !important;
    }
}

.modal[aria-labelledby=tn-modal-title] .modal-dialog .modal-content {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 80%;
    overflow-y: scroll;
    .tn-syos-legend {
        padding: 0;
    }
}
