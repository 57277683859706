@use "../base" as *;

.page-header--404,
.page-header--search {
    padding: 30px 0 var(--space--xl);
    color: var(--color--white);

    @include mq(m) {
        padding: 40px 0;
    }

    .post-header__heading {
        margin-bottom: var(--space--s);

        @include mq(m) {
            margin-bottom: var(--space--m);
        }
    }

    h5 {
        margin: 0 0 var(--space--s);

        @include mq(m) {
            margin: 0 0 var(--space--m);
        }
    }

    .searchblock {
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid;
        width: 100%;
        height: 50px;

        @include mq(m) {
            width: 400px;
            margin-bottom: var(--space--l);
        }
    }

    .search-form {
        label,
        button {
            display: none;
        }

        input {
            background: transparent;
            border: 0;
            padding: 0;
            font-size: 18px;
            color: #9d9d9d;
        }

        input::placeholder {
            @extend %type-body;
            color: #9d9d9d;
        }
    }
}

.page-template-404 {
    .article__content {
        margin-top: 32px;

        @include mq(m) {
            margin-top: 106px;
        }
    }
}
