@use "sass-mq" as *;
@use "../base" as *;

.text-content,
.main-content__text {
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
        margin-bottom: var(--space--l);

        @extend %bold;
    }

    a {
        @extend %link--secondary;
    }

    ul,
    ol {
        padding-left: var(--space--xl);

        li {
            @extend %type-body;

            &::marker {
                font-weight: 700;
            }
        }
    }

    .small {
        @extend %type-body-s;
    }
}
