@use "../base" as *;

.dialog-container,
.dialog-overlay {
    position: fixed;
    top: var(--wp-admin--admin-bar--height, 0);
    right: 0;
    bottom: 0;
    left: 0;

    height: 100%;

    @include no-scrollbar;
}

.dialog-container {
    z-index: 100;
    display: flex;

    &[aria-hidden="true"] {
        visibility: hidden;
    }

    .content {
        width: 90%;

        @include mq(m) {
            width: 640px;
        }

        iframe {
            width: 100%;
        }
    }
}

.dialog-overlay {
    background-color: rgba(0, 0, 0, 0.95);
}

.dialog-content {
    margin: auto;
    z-index: 2;
    position: relative;

    iframe {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
    }
}

.video-dialog {
    background-color: rgba(25, 24, 24, 0.8);
    align-items: center;
    justify-content: center;

    .btn--cross {
        display: block;
        margin: 0 0 0 auto;
    }
}
