@use "sass-mq" as *;
@use "../base" as *;

.single-season {
    .event-header {
        padding: var(--space--l) 0 0;

        @include mq(m) {
            padding: var(--space--xl) 0 0;
        }

        &-title {
            margin: 0;
        }

        &__flex {
            padding-bottom: 24px;
            border-bottom: 1px solid;
            margin-bottom: 16px;

            @include mq(m) {
                padding-bottom: 28px;
                // border-bottom: 0;
                margin-bottom: 20px;
            }
        }

        &__details {
            padding: 0 var(--space--m) 12px;

            @include mq(l) {
                padding: 0 0 var(--space--m);
            }
        }
    }

    .feed_cards {
        margin: 0;
        --grid--columns: 1;

        @include mq(m) {
            --grid--columns: 3;
        }
    }

    p.priority {
        margin-bottom: 12px;
    }
}
