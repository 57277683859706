@use "sass-mq" as *;
@use "../base" as *;

.site-footer {
    $this: #{&};
    padding-block: var(--space--layout--xs);
    padding-top: var(--space--layout--xs);

    @include mq(l) {
        padding-block: var(--space--layout);
    }

    &__inner {
        @include mq(l) {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: var(--space--l);
            row-gap: var(--space--layout--xs);
        }
    }

    &__section {
        grid-column: span 6;

        &:nth-child(2) {
            grid-column: span 6;
        }

        .logo {
            width: 345px;
            margin-bottom: var(--space--xl);
        }

        .footer-details {
            margin-bottom: 40px;

            @include mq(m) {
                margin-bottom: var(--space--xl);
            }

            h5 {
                margin: 0;
            }
        }

        .footer-links {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: var(--space--l);
            row-gap: var(--space--20);

            @include mq(m) {
                grid-template-columns: repeat(3, 1fr);
            }

            .links {
                .desktop {
                    display: none;

                    @include mq(m) {
                        display: block;
                        padding-top: 16px;
                    }
                }

                &.mobile {
                    margin-top: 0;
                }
            }
        }

        &.site-footer__supporters {
            grid-column: span 12;

            .type-body-s {
                font-weight: var(--fw--bold);
            }

            .partners {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: var(--space--l);
                margin-bottom: var(--space--m);

                svg {
                    width: auto;
                }

                img {
                    width: auto;
                    height: 36px;
                }

                .ace {
                    width: auto;
                    height: 76px;
                }
            }

            .space-32 {
                margin-bottom: var(--space--xl);
            }
        }
    }

    .social-list {
        --btn--size: 44px;
        --icon-size: 28px;

        display: flex;
        gap: var(--grid--column-gap);
        margin-top: 42px;
        align-items: center;

        @include mq(m) {
            margin-top: var(--space--xl);
        }
        
        a {
            padding: 0;

            svg {
                transition: all 500ms ease;
            }

            &:hover {
                svg {
                    fill: var(--200, rgba(205, 202, 201, 1));

                    path {
                        fill: var(--200, rgba(205, 202, 201, 1));
                    }
                }
            }
        }

        svg {
            fill: var(--color--white);
            width: var(--icon-size);
            height: auto;
        }
    }

    &__section-title {
        @extend %type-h5;

        margin-bottom: var(--space--base);
    }

    &__links {

        @include mq(m) {
            display: flex;
            flex-flow: row wrap;
            column-gap: var(--grid--column-gap);
        }

        #{$this}__section-title {
            @include mq(m) {
                width: 100%;
            }
        }

        &-menu {
            display: flex;
            flex-direction: column;
            flex: 1;

            @include mq(m) {
                gap: 4px;
            }

            a {
                text-decoration: none;

                @extend %type-body-s;

                &:hover,
                &:focus-visible {
                    text-decoration: underline;
                }
            }
        }

        svg {
            height: auto;
            margin-bottom: var(--space--xs);
        }
    }
}
