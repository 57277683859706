@use "sass-mq" as *;
@use "../base" as *;

.page-header--people {
    background: transparent !important;
    padding-top: 30px;

    @include mq(m) {
        padding-top: 40px;
    }

    &::before {
        content: "";
        width: 100%;
        height: calc(100% - 120px);
        background: var(--color--black);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        @include mq(m) {
            height: calc(100% - 48px);
        }
    }
}

.people-header {
    display: flex;
    gap: var(--space--xl);
    flex-direction: column-reverse;
    padding-top: 30px;

    @include mq(m) {
        gap: var(--space--20);
        padding-top: 0px;
        flex-direction: row;
    }

    .page-header__image {
        aspect-ratio: 1 / 1;

        @include mq(m) {
            max-width: 400px;
        }
    }

    .titles {
        @include mq(m) {
            padding-top: 120px;
        }

        h1 {
            margin-bottom: var(--space--s);

            @include mq(m) {
                margin-bottom: var(--space--m);
            }
        }

        h3 {
            margin: 0;
        }
    }
}
