@use "sass-mq" as *;
@use "../base" as *;

.tn-cart-component {
    max-width: var(--width--m);

    .tn-empty-cart-message {
        @extend %type-body-l;

        padding: var(--space--l);
        background-color: var(--color--neutral-100);
        border: 0;
        text-align: left;
        font-style: normal;
        color: var(--color--neutral-800);
        margin-bottom: var(--space--20);

        @include mq(m) {
            margin-bottom: var(--space--xl);
        }
    }
}

.tn-cart-buttons,
.tn-basket-button-container,
.tn-payment-component,
.tn-syos-mobile-cart-controls {
    max-width: var(--width--m);
    padding: 34px 24px;
    background-color: var(--color--green);
    text-align: right !important;
    align-items: center;
    justify-content: flex-start !important;
    gap: 20px;
    display: flex;

    .tn-cart-buttons__item-container {
        button {
            margin: 0;
            width: 100%;
        }
    }

    &__primary-action {
        @extend %btn;
        @extend %btn--secondary-large;

        &:hover {
            background-color: transparent;
        }
    }
}

.tn-basket-button-container {
    flex-direction: row;
    justify-content: flex-end !important;
}
