@use "../base" as *;

.memberships {
    .container {
        padding: 0;

        @include mq(m) {
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    .section__heading {
        margin-bottom: var(--space--xl);
        padding: 0 var(--space--m);

        @include mq(m) {
            padding: 0;
        }
    }

    .accordion-item {
        $self: &;
        border-top: 1px solid var(--color--white);

        &::before {
            content: none;
        }

        &__header {
            background-color: var(--color--black);
            color: var(--color--white);
            padding: var(--space--m);

            @include mq(m) {
                padding: var(--space--m) var(--space--l);
            }

            &:hover,
            &:focus-visible {
                @include color-context("neutral-950", $colors);
                background-color: var(--color--neutral-950-500);
                color: var(--color--black);

                .accordion-item__icon {
                    &::before {
                        background-color: var(--color--black) !important;
                    }
                }
            }

            &[aria-expanded="true"] {
                background-color: var(--color--neutral-950);

                @include color-context("neutral-950", $colors);

                .accordion-item__icon {
                    &::before {
                        background-color: var(--color--white) !important;
                    }
                }
            }
        }

        &__icon::before {
            background-color: var(--color--white) !important;
        }

        &__heading {
            @extend %type-h4;
            @extend %bold;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;

            span {
                @extend %type-body;

                &.price {
                    @extend %type-body-l;
                    @extend %bold;
                }
            }
        }

        &__content {
            padding: 0;
            // height: 100%;

            @include mq(m) {
                margin: var(--space--20);
            }

            &-panel {
                // display: flex;
                background-color: #fff;
                background-image: url("../../public/images/dots.svg");
                background-size: 4px;
                background-repeat: repeat;
            }

            &__inner {
                background: var(--color--white);
                padding: var(--space--m);
                // width: 100%;

                legend {
                    --form--row-gap: var(--space--m);
                }

                &__payments {
                    display: flex;
                    gap: var(--space--m);

                    &-item {
                        display: flex;
                        align-items: center;
                        gap: var(--space--xs);
                    }
                }

                &__buttons {
                    margin-top: var(--space--xl);

                    @include mq(m) {
                        display: flex;
                        gap: var(--space--m);
                        margin-top: var(--space--layout--xs);
                    }

                    button {
                        width: 100%;
                        margin-bottom: var(--space--m);

                        @include mq(m) {
                            width: 200px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
