@use "sass-mq" as *;
@use "../base" as *;

fieldset {
    margin: 0;

    h2 {
        @extend %type-h3;
    }

    .form-group {
        margin-bottom: var(--space--m);

        &:last-child {
            margin-bottom: 0;
        }
    }

    small {
        @extend %type-body-l;
        margin-left: 8px;
    }
}
