@use "../base" as *;

.page-template-front-page {
    overflow-x: hidden;

    .article__content {
        background: #0b0b0b;
        margin: 0;

        @include mq(m) {
            padding: 64px 0 0;
        }
    }

    .page-header {
        @include mq(m) {
            aspect-ratio: 16 / 9;
        }

        .hero-video {
            height: calc(100% - 225px);

            @include mq(m) {
                height: 100% !important;
            }
        }

        // @include mq(m) {
        //     aspect-ratio: inherit;
        // }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(
                0deg,
                #0b0b0b 20%,
                rgba(11, 11, 11, 0) 100%
            );
            z-index: 3;
            height: 384px;
        }

        &--overlay {
            .page-header__content {
                position: relative;
                background: rgba(194, 234, 46, 1);
                padding-top: 16px;
                padding-bottom: 52px;
                z-index: 5;

                .container {
                    @include mq(m) {
                        padding-right: 48px;
                    }
                }

                @include mq(m) {
                    background: transparent;
                    position: absolute;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .page-header__heading {
        text-wrap: wrap;
        margin-bottom: 0;
        color: var(--color--black);
        transition: all 1000ms ease;

        @extend %type-h1;

        &::before {
            position: absolute;
            left: -3000px;
            width: 3000px;
            content: "";
            top: 0;
            bottom: 0;
            transition: all 1000ms ease;
        }

        @include mq(m) {
            font-weight: 600;
            padding: 20px 30px 24px 0;
            width: 60%;
            color: var(--color--white);
            display: flex;
            flex-direction: column;
        }

        @include mq(l) {
            width: 50%;
        }

        @include mq(xl) {
            width: 40%;
        }

        &:hover {
            background: rgba(194, 234, 46, 0.9);
            color: var(--color--black);

            &::before {
                background: rgba(194, 234, 46, 0.9);
            }

            .btn--alt {
                color: var(--color--black);
                border: 1px solid var(--color--black);
            }
        }

        .btn--alt {
            width: auto !important;
            text-decoration: none;
            display: flex;
            margin-top: 16px;
            color: var(--color--black);
            justify-content: space-between;

            @include mq(m) {
                display: inline-flex;
                margin-top: 20px;
                border: 1px solid var(--color--white);
                width: 280px !important;
                color: var(--color--white);
            }

            &:hover {
                border: 1px solid var(--color--black);
                color: var(--color--white);
            }
        }
    }

    .related-content {
        background: linear-gradient(180deg, #edff9c 0%, #e1ff64 25%, #fff 100%);
        padding: 64px 0 0;

        .container {
            padding: 0;

            @include mq(m) {
                padding-inline-start: 48px;
                padding-inline-end: 78px;
            }
        }

        .section__heading {
            border-bottom: 1px solid;
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;
            padding: 0 var(--space--m) 12px;

            @include mq(m) {
                padding: 0 0 12px;
            }

            a {
                text-decoration: none;
                padding: 0 16px;
                font-size: 16px;
                height: 40px;

                @include mq(m) {
                    padding: 10px 34px;
                    font-size: 20px;
                    height: 53px;
                }
            }
        }

        .btn--alt {
            &::after {
                content: none;

                @include mq(m) {
                    content: "";
                }
            }
        }
    }

    .related-content__cards,
    .feed_cards {
        margin: 0;
        --grid--columns: 1;
        padding: 0 var(--space--m);

        @include mq(m) {
            --grid--columns: 3;
            padding: 0;
        }

        .card__content {
            background-color: transparent;
        }
    }

    .control-buttons {
        left: 16px;
        top: -84px;
        bottom: auto;
        right: 0;

        @include mq(m) {
            display: flex;
            bottom: 48px;
            padding-right: 78px;
            left: auto;
            top: auto;
        }
    }
}

@media all and (min-width: 635px) {
    .page-template-front-page .page-header .hero-video {
        height: calc(100% - 183px);
    }
}
