@use "sass-mq" as *;
@use "../base" as *;

.tn-account-update-page,
.tn-account-interests-page,
.tn-account-memberships-page,
.tn-upcoming-events-page {
    .tn-page-layout {
        &--two-column {
            display: block;
        }

        &__header-container {
            display: none;
        }

        &__sidebar-container {
            display: none;
        }

        &__main-container {
            padding: 0 !important;
            border: 0 !important;

            form {
                @include mq(m) {
                    width: var(--width--m);
                }
            }

            .tn-login-component,
            .tn-patron-component,
            .tn-address-component,
            .tn-component-contact-permissions {
                background: var(--color--neutral-100);
                padding: var(--space--layout--xs) var(--space--20);
                width: 100%;
                margin-bottom: var(--space--20);

                @include mq(m) {
                    padding: var(--space--layout--xs);
                    margin-bottom: var(--space--xl);
                }

                &__header {
                    margin: 0;
                }

                &__settings__item {
                    .tn-component__fieldset-radio {
                        .tn-component__radio-group {
                            margin: 0;
                            display: flex !important;
                        }
                    }
                }

                &__settings__item {
                    padding: 0;
                    margin: 0px;
                    display: block;
                    width: 100%;
                    border: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &__header {
                        margin-bottom: var(--space--20);

                        @extend %type-body-l;

                        .ng-binding {
                            display: inline-block;
                        }
                    }
                }
            }

            .row {
                &:nth-last-of-type(1) {
                    padding: 34px 24px;
                    background: var(--color--green);
                    text-align: right !important;
                    align-items: center;
                    justify-content: flex-start !important;
                    gap: 20px;

                    .col-xs-12.col-sm-6 {
                        width: 100%;
                        gap: 20px;
                        justify-content: flex-start;
                        flex-direction: row-reverse;
                        padding: 0;
                        
                        @include mq(m) {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
