@use "../base" as *;

.card {
    $this: &;

    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.explore-block-inner {
        transition: all 500ms ease;

        a {
            text-decoration: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: var(--space--m);
        background-color: var(--color--white);
        transition: all 500ms ease;

        ul {
            margin-bottom: var(--space--s);
            margin-top: 0;
        }
    }

    &__heading {
        @extend %type-h4;
        @extend %bold;

        text-wrap: balance;
        margin-top: 0;
        margin-bottom: var(--space--xs);

        &:first-child {
            margin-top: 0;
        }
    }

    &__date {
        @extend %type-body;
        margin-top: 0;
        color: var(--color--neutral-800);
        transition: all 500ms ease;
        margin-bottom: 8px;

        @include mq(m) {
            font-weight: var(--fw--bold);
        }
    }

    &__source {
        @extend %type-body;
        @extend %bold;
        margin: 0 0 8px;
        text-decoration: underline;
    }

    &__summary {
        position: relative;
        z-index: 2; // Make the text selectable above the fill link
        text-wrap: pretty;
        margin: 0 0 var(--space--m);

        @extend %type-body;
    }

    &:hover {
        .card__image {
            img {
                transform: scale(1.1);
            }
        }

        &.explore-block-inner {
            background: var(--color--black);

            .card__tags-item {
                background-color: var(--color--white);
                color: var(--color--black);
            }

            .card__content,
            .card__date,
            a {
                color: var(--color--white);
            }
        }
    }

    &__image {
        width: 100%;
        aspect-ratio: var(--card--image-aspect-ratio, 16 / 9);
        overflow: hidden;
        background-color: var(--color--neutral-100);
        position: relative;

        // .card__image-inner is the container for the actual image which has the img-fit utility class applied
        &-inner {
            width: 100%;
            height: 100%;
        }

        img {
            transform: scale(1);
            transition: all 1000ms ease;
        }

        .artice-icon {
            position: absolute;
            bottom: 0;
            left: 0;
            /* background: red; */
            background: rgba(244, 1, 151, 1);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__tags {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        transition: all 500ms ease;

        &-item {
            background-color: var(--color--black);
            padding: var(--space--xxs) var(--space--xs);
            font-size: 12px;
            line-height: 12px;
            color: var(--color--white);
            font-weight: 500;
            transition: all 500ms ease;
        }
    }

    &__actions {
        padding-top: 0;
        margin-top: auto;

        @include mq(xxl) {
            display: flex;
            gap: 16px;
            justify-content: space-between;
        }

        .btn {
            width: 100%;
            padding: 8px 18px;
            margin-top: var(--space--s);
            z-index: 9;
            position: relative;

            @include mq(xxl) {
                margin-top: 0;
            }
        }
    }

    &__location {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 4px;

        @extend %type-body;

        &--icon {
            width: 20px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            justify-content: center;

            svg {
                height: 21px;
            }
        }
    }

    &__btn {
        margin-top: var(--space--base);
    }

    // Makes the link expand to fill the entire card
    &__fill-link {
        &::before {
            content: "";
            inset: 0;
            position: absolute;
            z-index: 1;
        }
    }

    &__clickable {
        position: relative;
        z-index: 1;
    }

    &__role {
        font-size: calc(
            ((14 / 16) * 1rem) + (14 - 14) * var(--fluid--bp)
        ) !important;
        line-height: 1.66 !important;
        margin-bottom: var(--space--m) !important;
    }

    &.card--people {
        .card__content {
            padding: var(--space--m) 0 0;
        }

        .card__heading {
            margin-bottom: var(--space--xs);
        }

        .card__image {
            aspect-ratio: 1 / 1;

            .people-icon {
                position: absolute;
                left: 0;
                bottom: 0;
                background: var(--color--black);
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
