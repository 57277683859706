@use "../base" as *;

.search-results,
.search-no-results {
    .article__content {
        margin-top: 0;
    }

    .feed_cards {
        --grid--columns: 1;
        margin-top: 32px;

        .hidden-card {
            display: none;
        }

        @include mq(m) {
            margin-top: 64px;
            --grid--columns: 2;
        }

        @include mq(xl) {
            --grid--columns: 3;
        }
    }

    .discovery-block {
        margin-top: 32px;

        @include mq(m) {
            margin-top: 106px;
        }

        .button-wrapper {
            text-align: center;
        }

        .btn--alt {
            width: 100%;
            justify-content: space-between;
            margin: var(--space--l) 0;

            @include mq(m) {
                width: 400px;
                margin: 42px auto;
            }
        }
    }

    .subhead {
        ul {
            display: flex;
            gap: 20px !important;

            @include mq(m) {
                display: flex;
                gap: 0;
            }

            li {
                &::after {
                    left: 0 !important;
                    right: 0 !important;

                    @include mq(m) {
                        left: var(--space--20) !important;
                        right: var(--space--20) !important;
                    }
                }
            }
        }
    }

    .tab-item {
        cursor: pointer;
        color: var(--color--white);
        font-weight: 400 !important;
        padding: 15px 0 19px;

        @extend %type-body-l;
        font-family: var(--ff--secondary);

        @include mq(m) {
            padding: 13px var(--space--20) 17px;
            font-size: 20px;
            font-family: var(--ff--primary);
        }

        &.no-results {
            pointer-events: none;
            color: rgba(127, 124, 124, 1);

            &::after {
                content: none;
            }
        }

        &.active {
            &:hover {
                color: var(--color--white);
            }
        }
    }

    .tabs {
        .btn--alt {
            width: 400px;
            margin: 64px auto 0;
            justify-content: space-between;
        }
    }
}
