@use "../base" as *;

.main-content {
    .container {
        padding: 0;

        @include mq(m) {
            // padding-inline: var(--container--padding-inline);
            padding-inline-start: 48px;
            padding-inline-end: 78px;
        }
    }

    .inner-padding {
        padding-inline: var(--container--padding-inline);

        @include mq(m) {
            padding-inline: 0;
        }
    }

    &__intro {
        @extend %type-h3;
        margin-bottom: var(--space--20);

        @include mq(m) {
            margin-bottom: var(--space--prose);
        }
    }

    &__text {
        @extend %type-body-l;

        // For single event.
        &.text-expand {
            --text-expand--constrained-height: 10.5lh;

            &__toggle {
                margin-bottom: var(--space-16);
            }
        }

        @include mq(m) {
            padding-right: 96px;
        }
    }

    // Side Bar
    .flex {
        gap: 42px;
        justify-content: space-between;

        @include mq(ml) {
            display: flex;
        }
    }
}
