@use "sass-mq" as *;
@use "../base" as *;

.tn-error-page {
    .tn-heading-container {
        display: none;
    }

    .tn-not-found-component {
        padding: var(--space--l);
        background-color: var(--color--neutral-100);

        @include mq(m) {
            width: var(--width--m);
        }
    }
}
