@use "form-styles" as *;

.form-field--checkbox-radio,
%form-field--checkbox-radio {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-areas: "input label";
    column-gap: var(--input--gap);
    row-gap: var(--space--xxs);
    padding-top: 3px;
    font-weight: var(--fw--normal);

    + .form-field--checkbox,
    + .form-field--radio {
        margin-top: 20px;
    }

    > * {
        grid-column: label;
    }

    [type="checkbox"],
    [type="radio"] {
        grid-area: input;
    }

    label {
        font-weight: var(--fw--medium);
        margin-bottom: 0 !important;
    }
}

[type="checkbox"],
[type="radio"] {
    position: relative;
    appearance: none;
    background-color: var(--color--white);
    margin: 0 !important;
    cursor: pointer;
    font: inherit;
    color: currentColor;
    width: var(--checkbox--size);
    height: var(--checkbox--size);
    border: var(--input--border-width) solid var(--input--border-color);
    // transform: translateY(-3px);

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        width: var(--checkbox--check-size);
        height: var(--checkbox--check-size);
        opacity: 0;
        background-color: var(--color--black);
    }

    &:disabled {
        --color--accent: var(--color--pink);

        color: var(--color--disabled);
        cursor: not-allowed;

        ~ label {
            color: var(--color--disabled);
            cursor: not-allowed;
        }
    }

    ~ label {
        font-weight: var(--fw--medium);
        margin-bottom: 0 !important;
    }

    &:disabled {
        color: var(--color--pink);
    }
}

// Checkbox Input
.form-field--checkbox,
%form-field--checkbox {
    @extend %form-field--checkbox-radio;
}

%checkbox-input,
[type="checkbox"] {
    border-radius: var(--input--border-radius);

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::before {
        transition: all var(--transition--base);
        transition-property: background-color;
    }

    &::after {
        content: "";
        width: var(--checkbox--checkmark-size);
        height: var(--checkbox--checkmark-size);
        mask-image: url("../images/tick.svg");
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        background-color: currentColor;
        opacity: 0;
        transition: all var(--transition--base);
        transition-property: background-color;
    }

    &:checked::before,
    &:checked::after {
        opacity: 1;
    }
}

// Radio Input
.form-field--radio,
%form-field--radio {
    @extend %form-field--checkbox-radio;
}

[type="radio"] {
    border-radius: 50%;

    &::before {
        border-radius: 50%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(var(--check-scale, 0));
    }

    &:checked::before {
        --check-scale: 1;

        opacity: 1;
        transition: all var(--transition--base);
        transition-property: transform, opacity;
    }
}
