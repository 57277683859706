@use "sass-mq" as *;
@use "../base" as *;

.supporters {
    .section__heading {
        margin-bottom: var(--space--xl);

        @include mq(m) {
            margin-bottom: 0;
        }
    }

    .accordion-item {
        border: 0;

        &::before {
            content: none;
        }

        @include mq(m) {
            margin-top: var(--space--layout--xs);
        }

        &__header {
            padding: var(--space--m) 0 var(--space--20);
            border-bottom: 1px solid var(--color--black);
            position: relative;

            &::before {
                content: " ";
                display: block;
                position: absolute;
                border-top: 1px solid;
                bottom: -1px;
                width: 3000px;
                left: -3000px;

                @include mq(m) {
                    top: 60px;
                }
            }

            @include mq(m) {
                padding: 0 0 var(--space--20);
            }
        }

        &__content {
            display: block;

            &_logos {
                --grid--row-gap: var(--space--m);

                @include mq(m) {
                    --grid--columns: 3;
                    --grid--row-gap: 0;
                }

                .logo {
                    padding: 32px 60px 0;

                    @include mq(m) {
                        padding: 24px 28px 0;
                    }

                    img {
                        margin-bottom: 40px;
                        width: 201px;
                        height: 90px;
                        object-fit: contain;
                        transform: scale(1);
                        transition: all 500ms ease;
                    }

                    a {
                        // text-decoration: none;
                        transition: all 500ms ease;

                        &:hover {
                            img {
                                transform: scale(1.05);
                            }

                            p {
                                font-weight: var(--fw--bold);
                            }
                        }
                    }
                }
            }

            p {
                margin: 0;
                padding: 4px;
                text-align: center;
                transition: font-weight 500ms ease;

                @extend %type-body;
            }
        }
    }
}
