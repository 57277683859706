@use 'sass:map';
@use 'sass:list';

/// Sets the background colour, foreground (`color`) and any other properties that
/// are provided in the color map.
///
/// @example scss
///    @include color-context('blue');
///
/// @access public

@mixin color-context($background, $colors) {
    $color-map: map.get($colors, $background);

    @if ($background != 'background') {
        --color--background: var(--color--#{$background});
    }

    background-color: var(--color--background);

    @if (map.has-key($color-map, 'foreground')) {
        --color--foreground: #{map.get($color-map, 'foreground')};
        --focus--color: var(--color--foreground);

        color: var(--color--foreground);
    }

    @if (map.get($color-map, 'properties')) {
        $properties: map.get($color-map, 'properties');

        @each $property, $value in $properties {
            #{$property}: #{$value};
        }
    }
}
