@use "../base" as *;

.discovery-block {
    h2 {
        @extend %type-h1;
        margin-bottom: 28px;

        @include mq(m) {
            margin-bottom: var(--space--xl);
        }
    }

    &__cards {
        --grid--columns: 1;

        @include mq(m) {
            --grid--columns: 2;
        }

        @include mq(xl) {
            --grid--columns: 3;
        }

        .card__summary {
            display: none;

            @include mq(m) {
                display: block;
            }
        }
    }

    .btn--alt {
        display: inline-flex;
        margin-top: 24px;
        justify-content: space-between;
        text-decoration: none;
        width: 100%;
        transition: all 500ms ease;
        margin-bottom: 80px;

        @include mq(m) {
            width: 390px;
            margin-bottom: 48px;
        }
    }
}
