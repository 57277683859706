@use './global' as *;
@use './margin-trim' as *;

.section {
    @extend %margin-trim;

    margin-inline: auto;

    &__inner,
    > [class*="__inner"] {
        margin-inline: auto;
    }

    &.has-background {
        padding-block: var(--section--padding-block);
    }
}

:where(.section:not(:first-child)) {
    margin-block-start: var(--section--margin-block);
}

:where(.section:not(:last-child)) {
    margin-block-end: var(--section--margin-block);
}
