@use 'sass-mq' as *;
@use '../0-helpers/' as *;

/// Set up a CSS Grid with n equal columns using the --grid--columns and --grid--column-gap custom properties.
///
/// @example scss
///    @include grid;
///
/// @access public
%grid,
.grid {
    display: grid;
    grid-template-columns: repeat(var(--grid--columns), minmax(0, 1fr));
    column-gap: var(--grid--column-gap, 0px);
    row-gap: var(--grid--row-gap, 0px);
}

/// Set grid columns to automatically adjust to the --grid--item--min-width
///
/// @example scss
///    @extend %grid--auto;
%grid--auto,
.grid--auto {
    @extend %grid;

    grid-template-columns: repeat(auto-fill, minmax(min(var(--grid--item--min-width, #{rem(350)}), 100%), 1fr));
}


/// Create a flex grid with set number of columns and gap.
/// Preferable over using CSS Grid when you want the last row of items to be center aligned
///
/// @example scss
///    @include grid-columns;
///
/// @access public
%flex-grid,
.flex-grid {
    display: flex;
    flex-flow: row wrap;
    column-gap: var(--grid--column-gap, 0px);
    row-gap: var(--grid--row-gap, 0px);

    > * {
        @extend %flex-grid-item;
    }
}


/// Set items to fit a column width provided by --grid--columns and add the
/// grid--gap between them
///
/// @example scss
///    @include flex-grid-item;
///
/// @access public
%flex-grid-item,
.flex-grid-item {
    width: calc((100% / var(--grid--columns)) - var(--grid--column-gap, 0px) + (var(--grid--column-gap, 0px) / var(--grid--columns)));
}

/// A list of items which flows in a row and wraps to the next line when it reaches the end of the container
/// Useful for lists of labels, tags, etc.
///
/// @example scss
///    @extend %flex-list;
///
/// @access public
%flex-list,
.flex-list {
    display: flex;
    flex-flow: row wrap;
    column-gap: var(--grid--column-gap, 0px);
    row-gap: var(--grid--row-gap, 0px);
    align-items: center;
    justify-content: flex-start;
}


/// A helper to quickly set grid columns responsively without having to write out media queries.
/// Use with %grid or %flex-grid
///
/// @example scss
///    @extend %grid;
///    @include grid(2, 4, 6);
///
/// @access public
@mixin grid-columns($columns: null, $columns--m: null, $columns--l: null) {
    @if ($columns) {
        --grid--columns: #{$columns};
    }

    @if ($columns--m) {
        @include mq(m) {
            --grid--columns: #{$columns--m};
        }
    }

    @if ($columns--l) {
        @include mq(l) {
            --grid--columns: #{$columns--l};
        }
    }
}
