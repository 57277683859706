@use "../base" as *;

.article {
    &--sidebar {
        .article__top {
            @include mq(m) {
                display: grid;
                grid-template-columns: 60% 40%;
                gap: var(--space--base);
            }

            @include mq(xl) {
                gap: var(--space--xl);
            }
        }
    }

    &__top {
        display: grid;
        gap: var(--space--l);
        margin-bottom: var(--space--base);
    }

    &__content {
        @extend %margin-trim;
        margin-block: var(--space--l);

        @include mq(m) {
            margin-block: var(--article-content--margin-block);
        }
    }
}

:where(.article__content > *) {
    margin-inline: auto;
    // max-width: var(--width--max--padded);
}
