@use "sass-mq" as *;
@use "../base" as *;

.post-header {
    padding: var(--space--xl) 0;
    color: var(--color--white);

    @include mq(m) {
        padding: var(--space--xl) 0 64px;
    }

    &__tags {
        margin: 0 0 var(--space--m);
        list-style: none;
        display: flex;
        gap: 8px;
        padding: 0;

        &-item {
            background-color: var(--color--pink);
            padding: 4px 8px;
            color: var(--color--black);
            font-size: 12px;
            line-height: 12px;
            font-weight: var(--fw--bold);
        }
    }

    &__heading {
        margin: 0 0 var(--space--m);
    }

    &__date {
        margin: 0;

        @include mq(m) {
            margin: 0 0 var(--space--l);
        }
    }

    &__image {
        position: relative;

        img {
            transform: scale(1);
            transition: all 1000ms ease;
        }

        .btn-play {
            overflow: hidden;
        }

        .btn--icon {
            position: absolute;
            top: 0;
            left: 0;
            background: var(--color--black);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mq(m) {
                width: 64px;
                height: 64px;
            }

            svg {
                width: 11px;

                @include mq(m) {
                    width: 18px;
                }
            }
        }

        .btn-play:hover img {
            transform: scale(1.1) !important;
        }
    }

    .desktop {
        display: none;

        @include mq(m) {
            display: block;
        }
    }
}

.mobile {
    margin-top: var(--space--l);

    .page-header__image {
        aspect-ratio: 16 / 9;
    }

    @include mq(m) {
        display: none;
    }
}

//page specific
.single-post {
    .explore-block {
        margin-top: var(--space--l);

        @include mq(l) {
            margin-top: 0;
        }
    }
}
