@use "../0-helpers" as *;
@use "sass:map";
@use "sass:list";
@use "sass:color";

// Breakpoints for media queries
$mq-breakpoints: (
    xxs: 360px,
    xs: 400px,
    s: 576px,
    m: 768px,
    l: 992px,
    ml: 1048px,
    xl: 1200px,
    xxl: 1400px,

    menu: 992px,
    sidebar: 1047px,
);

@use "sass-mq" as * with (
    $breakpoints: $mq-breakpoints,

    // Uncomment to show current bp in the top right corner of the browser
    // $show-breakpoints: (xxs, xs, s, m, l, xl, xxl),
);

// Widths
$widths: (
    m: 819px,
    l: 1047px,
    max: 1366px,

    // Default max-width for content
    // Min and Max width for fluid units [e.g. rfs() and ru()]
    fluid-min: 320px,
    fluid-max: 1200px,
);

:root {
    // Define a container width with container-padding subtracted.
    --width--full--padded: calc(100% - (var(--section--margin-inline) * 2));

    @each $width in $widths {
        // Output each defined $widths as a custom property.
        // e.g. --width--l
        $name--s: "--width--#{list.nth($width, 1)}";
        $value--s: #{list.nth($width, 2)};

        #{$name--s}: #{$value--s};

        // Output a responsive width for each $widths as a custom property.
        // e.g. --width--l--padded
        $name--r: "--width--#{list.nth($width, 1)}--padded";
        $value--r: min(var(#{$name--s}), var(--width--full--padded));

        #{$name--r}: #{$value--r};
    }
}

$colors: (
    "black": (
        "colorValue": #000,
        "foreground": var(--color--white),
        "properties": (
            "--link--color": var(--color--white),
            "--link--color--hover": var(--color--white),
        ),
    ),
    "white": (
        "colorValue": #fff,
    ),

    // Orange
    "orange":
        (
            "colorValue": #e63329,
        ),

    "orange-dark": (
        "colorValue": #480a07,
    ),

    "orange-light": (
        "colorValue": #fff2f1,
    ),

    // Teal
    "teal":
        (
            "colorValue": #009193,
        ),

    "teal-dark": (
        "colorValue": #003135,
    ),

    "teal-light": (
        "colorValue": #eefffd,
    ),

    // Pink
    "pink":
        (
            "colorValue": #f40197,
        ),

    // Green
    "green":
        (
            "colorValue": #c2ea2e,
        ),

    "green-light": (
        "colorValue": #edff9c,
    ),

    // Neautrals
    "neutral-100":
        (
            "colorValue": #f4f3f3,
        ),

    "neutral-200": (
        "colorValue": #cdcac9,
    ),

    "neutral-500": (
        "colorValue": #7f7c7c,
    ),

    "neutral-700": (
        "colorValue": #4a4848,
    ),

    "neutral-800": (
        "colorValue": #333030,
    ),

    "neutral-900": (
        "colorValue": #191818,
    ),

    "neutral-950": (
        "colorValue": #0a0a0b,
        "foreground": var(--color--white),
        "properties": (
            "--link--color": var(--color--white),
            "--link--color--hover": var(--color--white),
        ),
    ),

    // Utility colors
    "accent":
        (
            "colorValue": #ffb800,
        ),
    "focus": (
        "colorValue": #000,
    ),
    "error": (
        "colorValue": #e02929,
    ),
    "disabled": (
        "colorValue": #595959,
    ),

    // Contextual colors
    "background":
        (
            "colorAlias": "white",
        ),
    "text": (
        "colorAlias": "black",
    ),
);

// Color maps for button colours
$btn-colors: (
    // The first palette in the map is used as the default button colours
    "black":
        (
            "background-color": transparent,
            "border-color": var(--color--black),
            "color": var(--color--black),
            "background-color--hover": var(--color--black),
            "border-color--hover": var(--color--black),
            "color--hover": var(--color--white),
        ),

    "white": (
        "background-color": var(--color--white),
        "border-color": var(--color--black),
        "color": var(--color--black),
        "background-color--hover": var(--color--neutral-200),
        "border-color--hover": var(--color--black),
        "color--hover": var(--color--black),
    ),

    "neutral-950": (
        "background-color": var(--color--neutral-950),
        "border-color": var(--color--neutral-950),
        "color": var(--color--white),
        "background-color--hover": var(--color--pink),
        "border-color--hover": var(--color--pink),
        "color--hover": var(--color--white),
    ),

    "teal": (
        "background-color": var(--color--teal),
        "border-color": var(--color--teal),
        "color": var(--color--black),
        "background-color--hover": var(--color--pink),
        "border-color--hover": var(--color--pink),
        "color--hover": var(--color--white),
    ),

    "neutral-200": (
        "background-color": var(--color--neutral-200),
        "border-color": var(--color--black),
        "color": var(--color--black),
        "background-color--hover": var(--color--pink),
        "border-color--hover": var(--color--black),
        "color--hover": var(--color--black),
    )
);

:root {
    @each $color-name, $color-map in $colors {
        $prop-name: "--color--#{$color-name}";

        $value: color($color-name, $colors);

        #{$prop-name}: #{$value};
        #{$prop-name}--hsl: #{hsl-from-hex($value)};

        #{$prop-name}--h: #{color.hue($value)};
        #{$prop-name}--s: #{color.saturation($value)};
        #{$prop-name}--l: #{color.lightness($value)};
    }

    // Spacing
    // Spacing for smaller elements
    --space--xxs: 4px;
    --space--xs: 8px;
    --space--s: 12px;
    --space--m: 16px;
    --space--20: 20px;
    --space--l: 24px;
    --space--xl: 32px;
    --space--xxl: 40px;

    // Spacing for larger layout elements
    --space--layout--xs: 48px;
    --space--layout--s: 64px;
    --space--layout--m: 80px;
    --space--layout--l: 100px;
    --space--layout--xl: 130px;

    --space--layout: #{ru(32, 48, "1px")}; // A default responsive spacing for layout elements

    // Contextual spacing
    --space--base: var(--space--l); // Base vertical spacing unit
    --space--prose: var(
        --space--base
    ); // Default spacing between .prose context elements
    --space--stack: var(
        --space--base
    ); // Default spacing between .stack context elements

    // Typography
    --fs--base: #{rem(16)};
    --fs--body: #{rfs(18, 16)};

    --fw--normal: 400;
    --fw--medium: 500;
    --fw--semibold: 600;
    --fw--bold: 700;

    --ff--primary: aktiv-grotesk-extended, sans-serif;
    --ff--secondary: aktiv-grotesk, sans-serif;

    --text-underline-offset: 0.15em;

    // Default heading margins (Override individual heading levels in type-styles.scss)
    --heading--margin-top: var(--space--layout--xs);
    --heading--margin-bottom: var(--space--prose);

    // Appearance Properties
    --border-radius--s: 4px;
    --border-radius--m: 6px;
    --border-radius--l: 15px;

    --border--1: 1px solid var(--color--black);

    --transition--duration: 200ms;
    --transition--base: var(--transition--duration) ease-out;

    // Buttons
    --btn--size: 40px;
    --btn--border-width: 1px;
    --btn--padding-block: var(--space--xs);
    --btn--padding-inline: var(--space--l);
    --btn--border-radius: 0px;
    --btn--font-weight: var(--fw--bold);

    // Set the default button colours using the first palette in $btn-colors
    // Get the first key from the map
    $first-key: list.nth(map.keys($btn-colors), 1);
    // Get the first item (map) using the first key
    $first-item: map.get($btn-colors, $first-key);

    // Iterate over the first item's properties
    @each $prop, $value in $first-item {
        // Construct the CSS custom property name
        $propname: "--btn--#{$prop}";

        #{$propname}: #{$value};
    }

    // Links
    --link--color: inherit;
    --link--color--hover: var(--color--neutral-700);
    --link--text-decoration: underline;
    --link--text-decoration--hover: underline;

    // Content
    --article-content--margin-block: 32px;

    @include mq(xl) {
        --article-content--margin-block: 64px;
    }

    // Container
    --container--padding-inline: 16px;

    @include mq(m) {
        --container--padding-inline: #{ru(16, 40, "1px")};
    }

    @include mq(xxl) {
        --container--padding-inline: 80px;
    }

    // Sections (.section) - main content blocks
    --section--max-width: var(--width--l);

    --section--margin-inline: var(--container--padding-inline);
    --section--margin-block: var(--space--layout);
    --section--padding-inline: var(--container--padding-inline);
    --section--padding-block: var(--space--layout);

    // Form Variables
    --form--column-gap: var(--space--l);
    --form--row-gap: var(--space--l);
    --form--label-spacing: var(--space--xs);

    --form--field--row-gap: var(--space--xs);
    --form--field--column-gap: var(--space--s);

    --input--background-color: var(--color--white);
    --input--background-color--focus: var(--color--white);

    --input--border-width: 1px;
    --input--border-color: currentColor;
    --input--border-radius: 0;
    --input--color: currentColor;
    --input--gap: var(--space--xs);
    --input--padding-block: calc(0.75em - var(--input--border-width));
    --input--padding-inline: calc(0.75em - var(--input--border-width));
    --input--placeholder-color: currentColor;

    --checkbox--size: 24px;
    --checkbox--check-size: 16px;
    --checkbox--checkmark-size: 20px;

    --radio--size: 24px;
    --radio--check-size: 16px;

    // Grids
    --grid--column-gap: var(--space--base);
    --grid--row-gap: var(--space--xl);
    --grid--columns: 1;
    --grid--item--min-width: #{rem(350)};

    @include mq(m) {
        --grid--columns: 2;
        --grid--column-gap: var(--space--l);
        --grid--row-gap: var(--space--xxl);
    }

    @include mq(l) {
        --grid--columns: 4;
    }

    // Tables
    --table--header--background-color: var(--color--white);
    --table--header--border: var(--border--1);
    --table--row--padding-block: var(--space--l);
    --table--row--padding-inline: var(--space--xs);
    --table--row--background-color--even: var(--color--white);
    --table--row--background-color--odd: var(--color--neutral-100);

    // Utilities

    // Fluid units and typography
    --fluid--min-width: #{strip-unit(map.get($widths, fluid-min))};
    --fluid--max-width: #{strip-unit(map.get($widths, fluid-max))};

    --fluid--screen: 100vw;
    --fluid--bp: calc(
        (var(--fluid--screen) - var(--fluid--min-width) / 16 * 1rem) /
            (var(--fluid--max-width) - var(--fluid--min-width))
    );

    @media screen and (min-width: #{map.get($widths, fluid-max)}) {
        --fluid--screen: calc(var(--fluid--max-width) * 1px);
    }
}
