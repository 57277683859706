@use "sass-mq" as *;
@use "../base" as *;

.input-group {
    display: flex;
    align-items: center;

    .tn-currency-symbol {
        background: #000;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 56px;
        color: #fff;
        margin-bottom: 0;
        flex-shrink: 0;
        border-radius: 0;
        border: 1px solid #000;

        @extend %type-h5;
        @extend %bold;
    }

    .tn-amount {
        max-width: 200px;
    }
}

// Selects
select {
    appearance: none;
    border: 1px solid var(--color--black) !important;
    border-radius: 0 !important;
    height: 56px !important;
}

.tn-ticket-selector__custom-amount-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }
}
